









































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Category, FieldsErrors } from '@/types';
import CategorySelectInput from './CategorySelectInput.vue';
import BaseConfirmDialog from '@/components/BaseConfirmDialog.vue';

@Component({
  components: {
    CategorySelectInput,
    BaseConfirmDialog,
  },
})
export default class CategoryEdit extends Vue {
  @Prop()
  public category!: Category;
  @State('categories', { namespace: 'categories' })
  public categories!: Category[];
  @Prop()
  public error?: boolean;
  @Prop()
  public fieldsErrors?: FieldsErrors;
  @Prop()
  public errorMessage?: string;
  protected showDeleteConfirmDialog: boolean = false;

  public mounted() {
    const name: any = this.$refs.name;
    if (name instanceof HTMLInputElement) {
      name.focus();
    }
  }
}

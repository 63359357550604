













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Bookmark } from '@/types';
import BookmarkListItem from '@/components/bookmark/ListItem.vue';
import BookmarkCondensedListItem from '@/components/bookmark/CondensedListItem.vue';
import BookmarkMobileListItem from '@/components/bookmark/MobileListItem.vue';
import draggable from 'vuedraggable';

@Component({
  components: {
    BookmarkListItem,
    BookmarkCondensedListItem,
    BookmarkMobileListItem,
    draggable
  }
})
export default class List extends Vue {
  @Action('post', { namespace: 'bookmarks' })
  public post: any;
  @Action('put', { namespace: 'bookmarks' })
  public put: any;
  @Action('refresh', { namespace: 'bookmarks' })
  public refresh: any;
  @Prop()
  public bookmarks!: Bookmark[];
  @Prop({ default: 'condensed' })
  public readonly layout!: string;
  @Prop({ default: false })
  public sortable!: boolean;
  public isChildEditModalVisible: boolean = false;

  get bkms() {
    /**
     * Work on a copy of the store bookmark's to avoid mutating them directly.
     */
    return this.bookmarks;
  }

  get isSortable() {
    return this.sortable && !this.isChildEditModalVisible;
  }

  protected async change(event: any) {
    if (event.hasOwnProperty('moved')) {
      const bookmark = event.moved.element;
      bookmark.order = event.moved.newIndex;
      await this.put(bookmark);
      this.refresh({ category_id: bookmark.category_id });
    }
  }
}







































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import { FieldsErrors, Bookmark, SmartTag } from '@/types';
import CategorySelectInput from '@/components/category/CategorySelectInput.vue';

@Component({
  components: {
    CategorySelectInput
  }
})
export default class BookmarkEdit extends Vue {
  @Prop()
  public bookmark!: Bookmark;
  @Prop()
  public error?: boolean;
  @Prop()
  public fieldsErrors?: FieldsErrors;
  @Action('put', { namespace: 'bookmarks' })
  public put: any;
  @Action('delete', { namespace: 'bookmarks' })
  public delete: any;
  @Action('fetch', { namespace: 'smartTags' })
  public fetchSmartTags: any;
  @State('smartTags', { namespace: 'smartTags' })
  public smartTags!: SmartTag[];
  @Getter('categoryById', { namespace: 'categories' })
  public categoryById: any;
  private selectedSmartTag: SmartTag = {} as SmartTag;

  public mounted() {
    const title: any = this.$refs.title;
    if (title instanceof HTMLInputElement) {
      title.focus();
    }

    if (this.smartTags.length === 0) {
      this.fetchSmartTags();
    }
  }

  @Watch('selectedSmartTag')
  protected selectedSmartTagChanged() {
    if (this.selectedSmartTag && this.selectedSmartTag.tags) {
      this.bookmark.tags = this.selectedSmartTag.tags;
    } else {
      this.bookmark.tags = [];
    }
  }

  get inputTags() {
    return (this.bookmark && this.bookmark.tags.join(', ')) || '';
  }

  set inputTags(newValue: string) {
    this.bookmark.tags = newValue.split(', ');
  }
}

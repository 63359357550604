

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Loading from '@/components/BaseLoading.vue';

@Component({
  components: {
    Loading,
  },
})
export default class MenuItem extends Vue {
  @Prop({ default: false })
  public collapsible!: boolean;
  @Prop({ default: false })
  public addButton!: boolean;
  @Prop({ default: false })
  public loading!: boolean;

  private showLinks: boolean = false;
  private collapsed: boolean = false;
  private editing: boolean = false;

  protected toggleCollapse() {
    if (!this.collapsible) {
      return;
    }
    this.collapsed = !this.collapsed;
  }

  get collapseLabel() {
    return this.collapsed ? 'Expand' : 'Collapse';
  }

  get showCollapseLabel() {
    return this.collapsible && this.showLinks && !this.editing;
  }

  get showAddButton() {
    return !this.editing;
  }
}








import { Component, Vue } from 'vue-property-decorator';
import Import from '@/components/Import.vue';

@Component({
  components: {
    Import
  }
})
export default class HomeImport extends Vue {}





































import { Component, Watch, Mixins } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Bookmark, BookmarkFilters, Category } from '../types';
import Loading from '@/components/BaseLoading.vue';
import Header from '@/components/bookmark/Header.vue';
import BookmarksList from '@/components/bookmark/List.vue';
import EditModal from '@/components/bookmark/EditModal.vue';
import EditMixin from '@/components/bookmark/EditMixin.vue';
import Pagination from '@/components/BasePagination.vue';

@Component({
  components: {
    Header,
    BookmarksList,
    EditModal,
    Pagination,
    Loading,
  },
  computed: {
    ...mapGetters('bookmarks', {
      filteredBookmarks: 'filteredBookmarks', // <- for runtime
    }),
  },
})
export default class Bookmarks extends Mixins(EditMixin) {
  @Getter('smartTagWithSlug', { namespace: 'smartTags' })
  public smartTagWithSlug: any;
  @Getter('smartTagById', { namespace: 'smartTags' })
  public smartTagById: any;
  @Getter('categoryWithSlug', { namespace: 'categories' })
  public categoryWithSlug: any;
  @Getter('categoryById', { namespace: 'categories' })
  public categoryById: any;
  public filteredBookmarks: any;
  @State('fetchError', { namespace: 'bookmarks' })
  public fetchError!: string;
  @State('isFetching', { namespace: 'bookmarks' })
  public isFetching!: boolean;
  @State('totalFiltered', { namespace: 'bookmarks' })
  public totalFiltered!: number;
  @State('filters', { namespace: 'bookmarks' })
  public filters!: BookmarkFilters;
  @Action('filter', { namespace: 'bookmarks' })
  public filter: any;
  public pageEllipsis: string = '...';
  protected bookmarkTemplate: Bookmark = {} as Bookmark;
  protected showModal: boolean = false;
  protected layout: string = 'condensed';
  private currentSearchText: string | undefined;

  get sortable() {
    return this.filters.categoryId != null;
  }

  get currentCategory() {
    return this.filters.categoryId !== undefined
      ? this.categoryById(this.filters.categoryId)
      : undefined;
  }

  get currentSmartTag() {
    return this.filters.smarttagId !== undefined
      ? this.smartTagById(this.filters.smarttagId)
      : undefined;
  }

  public mounted() {
    this.routeChanged();
  }

  @Watch('isFetching')
  public authenticatedChanged() {
    if (this.isFetching) {
      return;
    }
    this.routeChanged();
  }

  @Watch('$route')
  public async routeChanged() {
    const categorySlug = this.$route.params.categorySlug;
    let category: Category | undefined;
    if (categorySlug !== undefined) {
      category = this.categoryWithSlug(categorySlug);
    }
    const smartTagSlug = this.$route.params.smartTagSlug;
    let smartTag: Category | undefined;
    if (smartTagSlug !== undefined) {
      smartTag = this.smartTagWithSlug(smartTagSlug);
    }

    const currentFilters = this.filters;
    if (currentFilters !== undefined) {
      if (
        category !== null &&
        category !== undefined &&
        category.id !== currentFilters.categoryId
      ) {
        currentFilters.categoryId = category.id;
        currentFilters.page = 1;
      } else if (category === undefined) {
        // All bookmarks
        currentFilters.categoryId = undefined;
      }
      if (smartTag !== undefined && smartTag.id !== currentFilters.smarttagId) {
        currentFilters.smarttagId = smartTag.id;
        currentFilters.page = 1;
      } else if (smartTag === undefined) {
        // All bookmarks
        currentFilters.smarttagId = undefined;
      }
      this.filter(currentFilters);
    }
  }

  public selectPage(pageNumber: number) {
    this.filters.page = pageNumber;
    this.filter(this.filters);
    this.scrollToTop();
  }

  public search(searchText: string | undefined) {
    this.filters.search = searchText;
    this.filter(this.filters);
  }

  public sortBy(event: any) {
    if (!event) {
      return;
    }
    const field = event.target.name;
    if (this.filters.sort === field) {
      if (this.filters.sort === 'order') {
        // The order field is always sorted ASC
        return;
      }
      if (this.filters.sortAsc) {
        this.filters.sortAsc = false;
        return;
      }
      this.filters.sortAsc = true;
      this.search(this.currentSearchText);
      return;
    }
    this.filters.sort = field;
    this.filters.sortAsc = true;
    this.search(this.currentSearchText);
  }

  protected selectLayout(layout: string) {
    this.layout = layout;
  }

  protected addBookmark() {
    this.bookmarkTemplate = this.getBookmarkTemplate();
    this.showModal = true;
  }

  private getBookmarkTemplate(): Bookmark {
    const bookmarkTemplate = {} as Bookmark;
    if (this.filters.smarttagId !== undefined) {
      const smartTag = this.smartTagById(this.filters.smarttagId);
      bookmarkTemplate.tags = smartTag.tags || new Array();
    } else if (this.filters.categoryId !== undefined) {
      const category = this.categoryById(this.filters.categoryId);
      bookmarkTemplate.category_id = category.id;
      bookmarkTemplate.tags = category.tags || new Array();
    } else {
      bookmarkTemplate.tags = new Array();
    }
    return bookmarkTemplate;
  }

  private scrollToTop() {
    window.scrollTo(0, 0);
  }
}

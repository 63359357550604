import Vue from 'vue';
import Vuex from 'vuex';
import { Store } from 'vuex';
import { bookmarks } from './bookmarks';
import { categories } from './categories';
import { tags } from './tags';
import { smartTags } from './smartTags';
import { user } from './user';
import { dashboards } from './dashboards';
import { menu } from './menu';

Vue.use(Vuex);

// @ts-ignore
const store = new Store({
  // @ts-ignore
  modules: { bookmarks, categories, tags, smartTags, user, dashboards, menu }
});

export default store;

































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { Bookmark } from '@/types';
import EditModal from '@/components/bookmark/EditModal.vue';
import EditMixin from '@/components/bookmark/EditMixin.vue';

@Component({
  components: {
    EditModal
  }
})
export default class ListItem extends Mixins(EditMixin) {
  @Prop()
  public bookmark!: Bookmark;
  protected showModal: boolean = false;
  protected active: boolean = false;

  get dateCreated() {
    if (!this.bookmark) {
      return '';
    }
    return moment(this.bookmark.created).format('Do MMM YYYY');
  }

  get dateUpdated() {
    if (!this.bookmark) {
      return '';
    }
    return moment(this.bookmark.updated).format('Do MMM YYYY');
  }

  public removeAndEmit() {
    this.remove();
    this.$emit('show-modal', false);
  }
}


































import { Component, Vue } from 'vue-property-decorator';
import Menu from '@/components/TheMenu.vue';

@Component({
  components: {
    Menu,
  },
})
export default class Home extends Vue {}

import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Bookmark, BookmarkFilters, SmartTag } from '@/types';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;

const BOOKMARKS_FETCH_REQUEST = 'BOOKMARKS_FETCH_REQUEST';
const BOOKMARKS_FETCH_SUCCESS = 'BOOKMARKS_FETCH_SUCCESS';
const BOOKMARKS_FETCH_FAILURE = 'BOOKMARKS_FETCH_FAILURE';
const BOOKMARKS_PUT_REQUEST = 'BOOKMARKS_PUT_REQUEST';
const BOOKMARKS_PUT_SUCCESS = 'BOOKMARKS_PUT_SUCCESS';
const BOOKMARKS_PUT_FAILURE = 'BOOKMARKS_PUT_FAILURE';
const BOOKMARKS_DELETE_REQUEST = 'BOOKMARKS_DELETE_REQUEST';
const BOOKMARKS_DELETE_SUCCESS = 'BOOKMARKS_DELETE_SUCCESS';
const BOOKMARKS_DELETE_FAILURE = 'BOOKMARKS_DELETE_FAILURE';
const BOOKMARKS_REFRESH_REQUEST = 'BOOKMARKS_REFRESH_REQUEST';
const BOOKMARKS_REFRESH_SUCCESS = 'BOOKMARKS_REFRESH_SUCCESS';
const BOOKMARKS_REFRESH_FAILURE = 'BOOKMARKS_REFRESH_FAILURE';
const BOOKMARKS_FILTER = 'BOOKMARKS_FILTER';

export class State {
  public isFetching: boolean = false;
  public isPuting: boolean = false;
  public isDeleting: boolean = false;
  public bookmarks: Bookmark[] = [];
  public fetchError: boolean = false;
  public total: number = 0;
  public totalFiltered: number = 0;
  public count: number = 0;
  public filters: BookmarkFilters = {
    page: 1,
    limit: 20,
    sort: 'order',
    sortAsc: true
  } as BookmarkFilters;
  public categoryId: number | undefined = undefined;
}

const mutations = {
  [BOOKMARKS_FETCH_REQUEST](state) {
    state.isFetching = true;
  },
  [BOOKMARKS_FETCH_SUCCESS](state, payload) {
    state.isFetching = false;
    state.bookmarks = payload && payload.data;
    state.total = payload && payload.total;
    state.count = payload && payload.count;
  },
  [BOOKMARKS_FETCH_FAILURE](state) {
    state.isFetching = false;
    state.fetchError = true;
  },
  [BOOKMARKS_PUT_REQUEST](state) {
    state.isPuting = true;
  },
  [BOOKMARKS_PUT_SUCCESS](state, payload: Bookmark) {
    state.isPuting = false;
    const index = state.bookmarks.findIndex(bookmark => bookmark.id === payload.id);
    if (index !== -1) {
      Vue.set(state.bookmarks, index, payload);
    } else {
      state.bookmarks.push(payload);
    }
  },
  [BOOKMARKS_PUT_FAILURE](state) {
    state.isFetching = false;
  },
  [BOOKMARKS_DELETE_REQUEST](state) {
    state.isDeleting = true;
  },
  [BOOKMARKS_DELETE_SUCCESS](state, payload: Bookmark) {
    state.isDeleting = false;
    const index = state.bookmarks.findIndex(bookmark => bookmark.id === payload.id);
    state.bookmarks.splice(index, 1);
  },
  [BOOKMARKS_DELETE_FAILURE](state) {
    state.isDeleting = false;
  },
  [BOOKMARKS_REFRESH_REQUEST](state) {
    state.isFetching = true;
  },
  [BOOKMARKS_REFRESH_SUCCESS](state, payload: Bookmark[]) {
    state.isFetching = false;
    // This can be slow

    payload.forEach(bookmark => {
      const index = state.bookmarks.findIndex(stateBookmark => stateBookmark.id === bookmark.id);
      if (index !== -1) {
        Vue.set(state.bookmarks, index, bookmark);
      } else {
        state.bookmarks.push(bookmark);
        state.total = state.total + 1;
      }
    });
  },
  [BOOKMARKS_REFRESH_FAILURE](state) {
    state.isFetching = false;
    state.fetchError = true;
  },
  [BOOKMARKS_FILTER](state, payload: BookmarkFilters) {
    state.filters = Object.assign({}, payload);
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }, params): any {
    return new Promise((resolve, reject) => {
      commit(BOOKMARKS_FETCH_REQUEST);
      axios({
        url: `${API_URL}/bookmarks`,
        params
      }).then(
        response => {
          const payload = response && response.data;
          commit(BOOKMARKS_FETCH_SUCCESS, payload);
          resolve(payload);
        },
        error => {
          commit(BOOKMARKS_FETCH_FAILURE);
          reject(error);
        }
      );
    });
  },
  put({ commit }, payload: Bookmark): any {
    return new Promise((resolve, reject) => {
      commit(BOOKMARKS_PUT_REQUEST);
      const data = _.pick(payload, ['title', 'tags', 'url', 'category_id', 'order']);
      axios({
        url: `${API_URL}/bookmarks/${payload.id}`,
        method: 'put',
        data
      }).then(
        response => {
          const bookmark: Bookmark = response && response.data;
          commit(BOOKMARKS_PUT_SUCCESS, bookmark);
          resolve(bookmark);
        },
        error => {
          commit(BOOKMARKS_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  post({ commit }, payload: Bookmark): any {
    return new Promise((resolve, reject) => {
      commit(BOOKMARKS_PUT_REQUEST);
      const data = _.pick(payload, ['title', 'tags', 'url', 'category_id']);
      axios({
        url: `${API_URL}/bookmarks`,
        method: 'post',
        data
      }).then(
        response => {
          const bookmark: Bookmark = response && response.data;
          commit(BOOKMARKS_PUT_SUCCESS, bookmark);
          resolve(bookmark);
        },
        error => {
          commit(BOOKMARKS_PUT_FAILURE, error);
          reject(error);
        }
      );
    });
  },
  delete({ commit }, payload: Bookmark): any {
    return new Promise((resolve, reject) => {
      commit(BOOKMARKS_DELETE_REQUEST);
      axios({
        url: `${API_URL}/bookmarks/${payload.id}`,
        method: 'delete'
      }).then(
        response => {
          commit(BOOKMARKS_DELETE_SUCCESS, payload);
          resolve(response);
        },
        error => {
          commit(BOOKMARKS_DELETE_FAILURE);
          reject(error);
        }
      );
    });
  },
  refresh({ commit }, params): any {
    return new Promise((resolve, reject) => {
      commit(BOOKMARKS_REFRESH_REQUEST);
      axios({
        url: `${API_URL}/bookmarks`,
        params
      }).then(
        response => {
          const payload = response && response.data;
          commit(BOOKMARKS_REFRESH_SUCCESS, payload.bookmarks);
          resolve(payload);
        },
        error => {
          commit(BOOKMARKS_REFRESH_FAILURE);
          reject(error);
        }
      );
    });
  },
  filter({ commit }, payload: BookmarkFilters): any {
    commit(BOOKMARKS_FILTER, payload);
  }
} as ActionTree<State, any>;

const getters = {
  filteredBookmarks(state, mgetters): Bookmark[] | null {
    return mgetters.getFilteredBookmarks(state.filters);
  },
  getFilteredBookmarks: (state, _getters, _rootState, rootGetters) => (
    filters: BookmarkFilters
  ) => {
    // Can be called as a method, using @Getter
    let results = state.bookmarks.slice();

    // Filters
    if (filters.categoryId) {
      results = results.filter(bookmark => bookmark.category_id === filters.categoryId);
    }
    if (filters.smarttagId) {
      const smartTag: SmartTag = rootGetters['smartTags/smartTagById'](filters.smarttagId);
      if (smartTag.exact_match) {
        results = results.filter(bookmark =>
          smartTag.tags.every((tag: string) => bookmark.tags.includes(tag))
        );
      } else {
        results = results.filter(bookmark =>
          smartTag.tags.some((tag: string) => bookmark.tags.includes(tag))
        );
      }
    }
    if (filters.search !== undefined) {
      results = results.filter(bookmark => {
        return (
          // @ts-ignore
          bookmark.title.toLowerCase().includes(filters.search) ||
          // @ts-ignore
          bookmark.url.toLowerCase().includes(filters.search)
        );
      });
    }

    // Pagination and ordering
    state.totalFiltered = results.length;
    if (filters.sort) {
      results.sort((a: Bookmark, b: Bookmark) => {
        if (a[filters.sort] > b[filters.sort]) {
          return 1;
        }
        if (a[filters.sort] < b[filters.sort]) {
          return -1;
        }
        return 0;
      });
      if (!filters.sortAsc) {
        results.reverse();
      }
    }
    if (filters.page) {
      results = results.slice((filters.page - 1) * filters.limit, filters.page * filters.limit);
    }
    return results;
  },
  bookmarksForCategory(state): Bookmark[] | null {
    // @ts-ignore
    let b = state.bookmarks.slice();
    b = b.filter(bookmark => bookmark.category_id === state.categoryId);
    return b;
  }
} as GetterTree<State, Bookmark>;

export const bookmarks = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};













import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Category } from '@/types';

@Component({})
export default class CategorySelectInput extends Vue {
  // This is how v-model works, by passing a 'value' prop, and emitting an 'input' with
  // the new value.
  @Prop({ default: () => undefined })
  public value!: number | undefined;
  @Prop({ default: '' })
  public classProp!: string;
  @Getter('rootCategories', { namespace: 'categories' })
  private rootCategories!: Category[] | null;
  @Getter('children', { namespace: 'categories' })
  public children: any;
  private categoryDepthById: Map<number, number> = new Map();

  get categoryId() {
    return this.value || false;
  }

  set categoryId(value: number | false) {
    this.$emit('input', value);
  }

  // Used in the view template
  // @ts-ignore
  private indented(category: Category) {
    const depth = this.categoryDepthById.get(category.id);
    if (depth !== undefined) {
      return String.fromCharCode(160).repeat(depth * 3) + category.name;
    }
    return category.name;
  }

  /**
   * Returns an array of categories.
   * The array is ordered by parentality, meaning a category will be followed
   * by its children, and each child by its own children.
   */
  get orderedCategories() {
    const categories: Category[] = [];
    if (!this.rootCategories) {
      return categories;
    }
    this.rootCategories.forEach((category) => {
      categories.push(category);
      this.getChildrenCategories(categories, category, 0);
    });
    return categories;
  }

  private getChildrenCategories(categories: Category[], category: Category, depth: number) {
    if (!this.children(category)) {
      return categories;
    }
    this.children(category).forEach((child: Category) => {
      categories.push(child);
      this.categoryDepthById.set(child.id, depth);
      return this.getChildrenCategories(categories, child, depth + 1);
    });
    return categories;
  }
}

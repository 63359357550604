



































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Bookmark } from '@/types';
import moment from 'moment';
import EditModal from '@/components/bookmark/EditModal.vue';
import EditMixin from '@/components/bookmark/EditMixin.vue';

@Component({
  components: {
    EditModal
  }
})
export default class CondensedListItem extends Mixins(EditMixin) {
  @Prop()
  public bookmark!: Bookmark;
  @Prop({ default: false })
  public showUrl!: boolean;
  @Action('delete', { namespace: 'bookmarks' })
  public delete: any;
  @Action('put', { namespace: 'bookmarks' })
  public put: any;
  protected showModal: boolean = false;
  protected active: boolean = false;

  get dateCreated() {
    return moment(this.bookmark.created).format('Do MMM YYYY');
  }

  get dateUpdated() {
    return moment(this.bookmark.updated).format('Do MMM YYYY');
  }

  public removeAndEmit() {
    this.remove();
    this.$emit('show-modal', false);
  }
}
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

@Component({
  components: {},
})
export default class Home extends Vue {
  @State('isAuthenticated', { namespace: 'user' })
  private isAuthenticated!: boolean;
  @Action('fetch', { namespace: 'user' })
  protected fetchMe: any;

  public mounted() {
    if (this.$route.query.error) {
      this.$toasted.error('Error during login, please try again later.');
      return;
    }
    try {
      // Auto-login check :
      // Try to get user information to verify whether user is still authenticated or not.
      // If so, this will trigger the watcher on isAuthenticated, and user will be redirected to the bookmarks page.
      this.fetchMe();
    } catch (error) {
      // Do nothing
    }
  }

  @Watch('isAuthenticated')
  protected authenticatedChanged() {
    if (this.isAuthenticated) {
      this.$router.push({ path: '/bookmarks' });
    }
  }
}

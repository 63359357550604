
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Category, FieldsErrors } from '@/types';
import { APIError, ValidationError } from '@/errors';

export const EVENT = {
  EDIT_SAVE_SUCCESS: 'EDIT_SAVE_SUCCESS',
  EDIT_SAVE_FAILURE: 'EDIT_SAVE_FAILURE',
  EDIT_DELETE_SUCCESS: 'EDIT_DELETE_SUCCESS',
  EDIT_DELETE_FAILURE: 'EDIT_DELETE_FAILURE',
  EDIT_CANCELED: 'EDIT_CANCELED',
};

@Component({})
export default class EditMixin extends Vue {
  @Action('delete', { namespace: 'categories' })
  public delete: any;
  @Action('put', { namespace: 'categories' })
  public put: any;
  @Action('post', { namespace: 'categories' })
  public post: any;
  @Action('fetch', { namespace: 'categories' })
  public fetch: any;
  @State('rootCategory', { namespace: 'categories' })
  protected rootCategory?: Category;
  protected saveError: boolean = false;
  protected saveErrorMessage: string = '';
  protected saveFieldsErrors: FieldsErrors = {};
  private originalCategory?: Category;
  protected category: Category = {} as Category;
  protected dirty: boolean = false;
  private unwatch: any = () => {};

  // @ts-ignore
  protected editEvent(event: string, payload: any) {
    // Can be overrided
  }

  protected editionStarted() {
    this.originalCategory = Object.assign({}, this.category);
    this.dirty = false;
    this.unwatch = this.$watch(
      'category',
      () => {
        this.dirty = true;
      },
      { deep: true }
    );
  }

  protected remove() {
    if (this.category !== undefined) {
      this.delete(this.category)
        .then(() => {
          this.unwatch();
          this.dirty = false;
          this.editEvent(EVENT.EDIT_DELETE_SUCCESS, null);
        })
        .catch((error: APIError) => {
          this.editEvent(EVENT.EDIT_DELETE_FAILURE, error);
        });
    }
  }

  protected async save() {
    if (!this.category) {
      return;
    }
    this.saveError = false;
    this.saveFieldsErrors = {};
    try {
      if (this.category.id !== undefined) {
        await this.put(this.category);
      } else {
        await this.post(this.category);
        if (!this.rootCategory) {
          await this.fetch();
        }
      }
      this.unwatch();
      this.dirty = false;
      this.editEvent(EVENT.EDIT_SAVE_SUCCESS, this.category);
    } catch (error) {
      this.saveError = true;
      if (error instanceof ValidationError && error.validation !== undefined) {
        this.saveFieldsErrors = error.validation;
      } else {
        let message = error;
        if (error && error.detail && error.detail.includes('unique')) {
          message = 'A category with that name already exists';
        }
        this.saveErrorMessage = message;
      }
      this.editEvent(EVENT.EDIT_SAVE_FAILURE, error);
    }
  }

  protected cancel() {
    this.unwatch();
    this.dirty = false;
    Object.assign(this.category, this.originalCategory);
    this.saveError = false;
    this.saveFieldsErrors = {};
    this.editEvent(EVENT.EDIT_CANCELED, null);
  }
}








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseMenuLoading extends Vue {
  @Prop({ default: 1 })
  public count!: number;
  @Prop({
    default: {
      margin: '8px 0'
    }
  })
  public itemStyle!: object;
}

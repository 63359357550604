





























































































import { Component, Mixins } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { SmartTag } from '../types';
import Loading from '@/components/BaseLoading.vue';
import BaseTable from '@/components/BaseTable.vue';
import EditModal from '@/components/smarttag/EditModal.vue';
import EditMixin from '@/components/smarttag/EditMixin.vue';
import BaseConfirmDialog from '@/components/BaseConfirmDialog.vue';

@Component({
  components: {
    BaseTable,
    Loading,
    EditModal,
    BaseConfirmDialog
  }
})
export default class Home extends Mixins(EditMixin) {
  @Action('post', { namespace: 'smartTags' })
  public post: any;
  @Action('get', { namespace: 'smartTags' })
  public getSmartTag: any;
  @Getter('smartTagWithSlug', { namespace: 'smartTags' })
  public smartTagWithSlug: any;
  @State('fetchError', { namespace: 'smartTags' })
  public fetchError!: string;
  @State('isFetching', { namespace: 'smartTags' })
  public isFetching!: boolean;
  @State('smartTags', { namespace: 'smartTags' })
  public smartTags!: SmartTag[];
  public columns = [
    { name: 'name', label: 'Name' },
    { name: 'exact_match', label: 'Exact Match' },
    { name: 'tags', label: 'Tags' },
    { name: 'created', label: 'Created' },
    { name: 'updated', label: 'Modified' }
  ];
  protected showModal: boolean = false;
  protected showConfirmModal: boolean = false;
  protected smartTagTemplate: SmartTag = {} as SmartTag;
  protected selectedSmartTag: SmartTag = {} as SmartTag;

  public addSmartTag() {
    this.selectedSmartTag = this.getSmartTagTemplate();
    this.showModal = true;
  }

  public getSmartTagTemplate(): SmartTag {
    const smartTagTemplate = {} as SmartTag;
    smartTagTemplate.tags = [];
    smartTagTemplate.exact_match = false;
    return smartTagTemplate;
  }

  public editSmartTag(smartTag: SmartTag) {
    this.selectedSmartTag = smartTag;
    this.showModal = true;
  }

  public editSmartTagDone() {
    this.selectedSmartTag = {} as SmartTag;
    this.showModal = false;
  }

  public deleteSmartTag(smartTag: SmartTag) {
    this.selectedSmartTag = smartTag;
    this.showConfirmModal = true;
  }

  public async confirmDeleteSmartTag(smartTag: SmartTag) {
    await this.delete(smartTag);
    this.showConfirmModal = false;
    this.showModal = false;
  }

  public deleteSmartTagDone() {
    this.selectedSmartTag = {} as SmartTag;
    this.showConfirmModal = false;
    this.showModal = false;
  }
}

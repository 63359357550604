
































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseTable<T extends object> extends Vue {
  @Prop({ default: () => [] })
  public columns!: [];
  @Prop({ default: () => [] })
  public entries!: T[];
  @Prop({ default: true })
  public header!: boolean;

  get showHeader() {
    return this.header;
  }

  public getNestedProperty(obj: T, property: string): any {
    const properties: string[] = property.split('.');
    return this.getProperty(obj, ...properties);
  }

  public getProperty(obj: any, ...props: string[]): any {
    return obj && props.reduce((result, prop) => (result == null ? undefined : result[prop]), obj);
  }
}

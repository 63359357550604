
































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Pagination extends Vue {
  public pageEllipsis: string = '...';
  @Prop({ default: 1 })
  private currentPage!: number;
  @Prop({ default: 1 })
  private totalPages!: number;
  @Prop({ default: 1 })
  private limit!: number;

  get numberOfPages() {
    return Math.ceil(this.totalPages / this.limit);
  }

  /**
   * Create and return an array of page numbers that should be displayed
   * in the paginator.
   * The array will contain contiguous pages relative to the current page with a delta,
   * and ellipsis when needed.
   */
  get pageRange() {
    const delta = 3;
    const range = [];

    for (
      let i = Math.max(2, this.currentPage - delta);
      i <= Math.min(this.numberOfPages - 1, this.currentPage + delta);
      i += 1
    ) {
      // Fill the contiguous pages based on the delta and current page number,
      // and the limits (minimum 1, maximum total number of pages).
      range.push(i);
    }

    if (this.currentPage - delta > 2) {
      // Add ellipsis at the beginning if current page number is high enough.
      range.unshift(this.pageEllipsis);
    }
    if (this.currentPage + delta < this.numberOfPages - 1) {
      // Add ellipsis at the end if current page is low enough.
      range.push(this.pageEllipsis);
    }

    // Always show first page number, add it at the beginning
    range.unshift(1);
    if (this.numberOfPages !== 1) {
      // If more than 1 page, add the last page number at the end.
      range.push(this.numberOfPages);
    }

    return range;
  }
}

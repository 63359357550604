







































import { Component, Vue } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import Loading from '@/components/BaseLoading.vue';
import ConfirmDialog from '@/components/BaseConfirmDialog.vue';

@Component({
  components: {
    Loading,
    ConfirmDialog
  }
})
export default class Login extends Vue {
  @Action('delete', { namespace: 'user' })
  protected userDelete: any;
  @State('isFetching', { namespace: 'user' })
  protected isFetching!: boolean;
  @State('isDeleting', { namespace: 'user' })
  protected isDeleting!: boolean;
  @State('name', { namespace: 'user' })
  protected name!: boolean;
  @State('nickname', { namespace: 'user' })
  protected nickname!: boolean;
  @State('picture', { namespace: 'user' })
  protected picture!: boolean;
  protected displayDialog: boolean = false;

  protected deleteMe() {
    this.userDelete()
      .then(() => {
        this.$toasted.success(`Your account has been deleted.`);
        this.$router.push({ path: '/' });
      })
      .catch((e: any) => {
        this.$toasted.error(`${e}`);
      });
  }
}

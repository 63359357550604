






















import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import { BookmarkFilters, Dashboard } from '../types';
import Loading from '@/components/BaseLoading.vue';
import Header from '@/components/bookmark/Header.vue';
import BookmarksList from '@/components/bookmark/List.vue';
import EditModal from '@/components/bookmark/EditModal.vue';
import EditMixin from '@/components/bookmark/EditMixin.vue';
import Pagination from '@/components/BasePagination.vue';

@Component({
  components: {
    Header,
    BookmarksList,
    EditModal,
    Pagination,
    Loading,
  },
})
export default class BookmarksDashboard extends Mixins(EditMixin) {
  @Action('fetch', { namespace: 'dashboards' })
  public fetch: any;
  @State('isFetching', { namespace: 'dashboards' })
  public isFetching: any;
  @Getter('getFilteredBookmarks', { namespace: 'bookmarks' })
  public getFilteredBookmarks: any;
  @Getter('dashboardWithSlug', { namespace: 'dashboards' })
  public dashboardWithSlug: any;
  @Getter('dashboardById', { namespace: 'dashboards' })
  public dashboardById: any;
  public dashboard?: Dashboard = {} as Dashboard;

  public filters: BookmarkFilters = {
    page: 1,
    limit: 20,
    sort: 'order',
    sortAsc: true,
  } as BookmarkFilters;

  @Watch('dashboard', { deep: true })
  public watchDashboard() {
    console.log('Dashboard changed');
  }

  get panels() {
    return this.dashboard && this.dashboard.configuration
      ? this.dashboard.configuration.panels
      : [];
  }

  public mounted() {
    this.routeChanged();
  }

  @Watch('isFetching')
  protected authenticatedChanged() {
    if (this.isFetching) {
      return;
    }
    this.routeChanged();
  }

  @Watch('$route')
  private async routeChanged() {
    const dashboardSlug = this.$route.params.dashboardSlug;
    if (dashboardSlug !== undefined) {
      this.dashboard = this.dashboardWithSlug(dashboardSlug);
    }
  }

  private getBookmarksForCategory(categoryId: number) {
    this.filters.categoryId = categoryId;
    return this.getFilteredBookmarks(this.filters);
  }
}

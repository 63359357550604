export interface Bookmark {
  id: number;
  created: string;
  updated: string;
  url: string;
  description: string;
  title: string;
  category_id: number;
  order: number;
  tags: string[];
}

export interface BookmarkFilters {
  categoryId: number | undefined;
  smarttagId: number | undefined;
  tags: string[];
  sort: 'id' | 'created' | 'updated' | 'url' | 'description' | 'title' | 'order';
  sortAsc: boolean;
  search: string | undefined;
  page: number;
  limit: number;
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  created: string;
  updated: string;
  order: number;
  parent_id?: number;
  children?: Category[];
  bookmarks?: Bookmark[];
  placeholder: boolean;
}

export interface CategoryFilters {
  search: string | undefined;
  page: number;
  limit: number;
}

export interface Tag {
  name: string;
}

export interface SmartTag {
  id: number;
  name: string;
  slug: string;
  created: string;
  updated: string;
  order: number;
  tags: string[];
  exact_match: boolean;
}

export interface FieldsErrors {
  [field: string]: string;
}

export interface Dashboard {
  id: number;
  name: string;
  slug: string;
  created: string;
  updated: string;
  order: number;
  configuration: DashboardConfiguration;
}

export interface DashboardConfiguration {
  panels: DashboardPanel[];
}

export interface DashboardPanel {
  id: number;
  category_id: number | undefined;
  category?: Category;
  title: string;
}

export interface MoveItems {
  id: number;
  from: number;
  to: number;
  new_parent_id?: number;
}

export interface DraggableMovedEvent {
  from: HTMLElement;
  to: HTMLElement;
  position: DraggablePosition;
}

export enum DraggablePosition {
  before = "BEFORE",
  inside = "INSIDE",
  after = "AFTER"
}

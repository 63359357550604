
















import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import EditMixin, { EVENT } from '@/components/smarttag/EditMixin.vue';
import EditForm from '@/components/smarttag/EditForm.vue';
import Modal from '@/components/BaseModal.vue';

@Component({
  components: {
    EditForm,
    Modal
  }
})
export default class EditModal extends Mixins(EditMixin) {
  @Prop()
  public showModal: boolean = false;

  @Watch('showModal')
  public onShowModalChanged() {
    this.editionStarted();
  }

  protected editEvent(event: string) {
    switch (event) {
      case EVENT.EDIT_SAVE_SUCCESS:
        this.$toasted.success(`Bookmark ${this.smartTag.name} saved`);
        this.$emit('close');
        this.$emit('success');
        break;
      case EVENT.EDIT_SAVE_FAILURE:
        this.$toasted.error(`Could not save smart tag ${this.smartTag.name}`);
        break;
      case EVENT.EDIT_DELETE_SUCCESS:
        this.$toasted.success(`Bookmark ${this.smartTag.name} deleted`);
        this.$emit('close');
        this.$emit('success');
        break;
      case EVENT.EDIT_DELETE_FAILURE:
        this.$toasted.error(`Could not delete smart tag ${this.smartTag.name}`);
        break;
      case EVENT.EDIT_CANCELED:
        this.$emit('close');
    }
  }
}

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faBookmark,
  faList,
  faTags,
  faUserCircle,
  faSearch,
  faCog,
  faPen,
  faCheck,
  faAngleDown,
  faPlusCircle,
  faSyncAlt,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSort,
  faSortUp,
  faSortDown,
  faFileImport,
  faColumns,
  faPlus,
  faPlusSquare,
  faMinusCircle,
  faGripLines,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { faBookmark as faBookmarkRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Adding only icons used in the application here
// results in only these icons in the final build
// See vue-fontawesome tree shaking
library.add(
  faBookmark,
  faBookmarkRegular,
  faHome,
  faList,
  faTags,
  faUserCircle,
  faSearch,
  faCog,
  faPen,
  faCheck,
  faAngleDown,
  faPlusCircle,
  faSyncAlt,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSort,
  faSortUp,
  faSortDown,
  faFileImport,
  faColumns,
  faPlus,
  faPlusSquare,
  faMinusCircle,
  faGripLines,
  faTimes
);

export { FontAwesomeIcon };

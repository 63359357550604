import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import MenuLayout from '@/views/layouts/MenuLayout.vue';
import BlankLayout from '@/views/layouts/BlankLayout.vue';
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import Bookmarks from '@/views/Bookmarks.vue';
import BookmarksDashboard from '@/views/BookmarksDashboard.vue';
import BookmarkAdd from '@/views/BookmarkAdd.vue';
import SmartTags from '@/views/SmartTags.vue';
import Import from '@/views/Import.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import Me from '@/views/Me.vue';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: EmptyLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: Home
        }
      ]
    },
    {
      path: '/',
      component: BlankLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login
        },
        {
          path: '/me',
          name: 'me',
          component: Me,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/bookmarks',
      component: MenuLayout,
      children: [
        {
          path: '',
          name: 'bookmarks',
          component: Bookmarks,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/import',
          name: 'import',
          component: Import,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/smart/:smartTagSlug',
          name: 'smarttag',
          component: Bookmarks,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/category/:categorySlug',
          name: 'category',
          component: Bookmarks,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/dashboard/:dashboardSlug',
          name: 'dashboard',
          component: BookmarksDashboard,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/smarttags',
      component: BlankLayout,
      children: [
        {
          path: '',
          name: 'smarttags',
          component: SmartTags,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/bookmark/add',
      component: BookmarkAdd,
      meta: {
        requiresAuth: true
      }
    }
  ],
  scrollBehavior(to) {
    if (to.hash) {
      const element: HTMLElement = document.querySelector(to.hash) as HTMLElement;
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
        return;
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
});

// @ts-ignore
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // @ts-ignore
    if (!store.state.user.isAuthenticated) {
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.use(Router);

export default router;





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { DashboardPanel, FieldsErrors, Category } from '@/types';
import CategorySelectInput from '@/components/category/CategorySelectInput.vue';

@Component({
  components: {
    CategorySelectInput
  }
})
export default class PanelEditField extends Vue {
  @Prop()
  public panel!: DashboardPanel;
  @Getter('categoryById', { namespace: 'categories' })
  private categoryById: any;

  get categoryId() {
    return this.panel.category_id;
  }

  set categoryId(categoryId: number | undefined) {
    if (categoryId == null) {
      this.panel.category_id = undefined;
      return;
    }
    this.panel.category_id = categoryId;
    if (this.panel.title == null || this.panel.title == '') {
      this.title = this.categoryById(categoryId).name;
    }
  }

  get title() {
    return this.panel.title;
  }

  set title(title: string) {
    this.panel.title = title;
  }

  public removePanel() {
    this.$emit('remove', this.panel);
  }
}

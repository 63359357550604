










































import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/BaseModal.vue';

@Component({
  components: {
    Modal
  }
})
export default class ConfirmDialog extends Vue {}

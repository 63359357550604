











































import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Import extends Vue {
  API_URL = process.env.VUE_APP_API_LOCATION;
  protected file?: File = undefined;
  protected fileName: string = '';
  protected error?: boolean = false;
  protected inProgress?: boolean = false;

  protected fileChanged() {
    const fileElement: any = this.$refs.file;
    if (fileElement !== null && fileElement instanceof HTMLInputElement) {
      const fileList = fileElement.files;
      if (fileList !== null) {
        this.file = fileList[0];
        this.fileName = this.file.name;
      }
    }
  }

  protected upload() {
    if (this.file === undefined) {
      return;
    }
    this.inProgress = true;
    const self = this;

    const formData = new FormData();
    formData.append('file', this.file);
    axios
      .post(`${this.API_URL}/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        self.inProgress = false;
        self.$router.push({ path: 'bookmarks', query: { refresh: '1' } });
        self.$toasted.success(`Your bookmarks have been imported.`);
      })
      .catch(() => {
        self.inProgress = false;
        self.error = true;
      });
  }
}









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';

@Component({
  components: {},
})
export default class Login extends Vue {
  @Prop()
  public nextUrl?: string;
  @Action('fetch', { namespace: 'user' })
  protected fetchMe: any;
  @State('isAuthenticated', { namespace: 'user' })
  protected isAuthenticated!: boolean;
  @State('isFetching', { namespace: 'user' })
  protected isFetching!: boolean;
  protected error: boolean = false;
  protected defaultNextUrl = 'bookmarks';

  protected removeTrailingSlash(str: string) {
    return str.endsWith('/') ? str.slice(0, -1) : str;
  }

  protected removeLeadingSlash(str: string) {
    return str.startsWith('/') ? str.slice(1) : str;
  }

  public async mounted() {
    try {
      // Try to get the user information from the API.
      // If it works, the isAutheticated will be true, and the user will be redirected to the next URL.
      // If the user is not authenticated, redirect to login endpoint of the OIDC proxy, that will start the authentication process.
      // Once the user has been authenticated by the proxy, he will be redirected to a protected page (defined in the proxy OAuth parameters).
      // A check in the router will ensure the isAuthenticated is true. Because this app was completely reloaded, we still believe
      // at this point that the user is not authenticated. So the router will redirect him to this Login page.
      // The fetchMe is triggered again, but this time it will succeed.
      const res = await this.fetchMe();
    } catch (error) {
      console.debug('Error status ' + error.status);
      console.debug({ error });
      var host = this.removeTrailingSlash(window.location.host);

      // @ts-ignore
      let nextUrl = this.removeLeadingSlash(this.$route.query.nextUrl || this.defaultNextUrl);
      var protocol = window.location.protocol;
      localStorage.nextUrl = nextUrl;
      var redirectTo = encodeURIComponent(protocol + '//' + host + '/' + nextUrl);
      window.location.href = '' + process.env.VUE_APP_AUTH_LOGIN_PATH + '?rd=' + redirectTo;
    }
    if (this.isAuthenticated) {
      this.redirectToNextUrl();
    }
  }

  private redirectToNextUrl() {
    let nextUrl = this.$route.query.nextUrl || this.defaultNextUrl;
    if (localStorage.getItem('nextUrl')) {
      // @ts-ignore
      nextUrl = localStorage.nextUrl;
      localStorage.removeItem('nextUrl');
      localStorage.removeItem('loginAttempts');
    }
    // @ts-ignore
    this.$router.push({ path: nextUrl });
  }
}

import { FieldsErrors } from './types';

// tslint:disable:max-classes-per-file
class APIError extends Error {
  public status: number;
  public detail?: string;

  constructor(status: number, title: string, detail?: string) {
    super(title); // 'Error' breaks prototype chain here
    // see https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.message = title;
    this.status = status;
    this.detail = detail;
  }
}

class ValidationError extends APIError {
  public validation?: FieldsErrors;

  constructor(status: number, title: string, detail?: string, validation?: FieldsErrors) {
    super(status, title, detail);
    Object.setPrototypeOf(this, new.target.prototype);
    this.validation = validation;
  }
}

export { APIError, ValidationError };
















import { Component, Vue } from 'vue-property-decorator';
import CategoriesMenu from './category/Menu.vue';
import DashboardsMenu from './dashboard/Menu.vue';

@Component({
  components: {
    CategoriesMenu,
    DashboardsMenu
  }
})
export default class Menu extends Vue {
  private scrollToTop() {
    window.scrollTo(0, 0);
  }
}

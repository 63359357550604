import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree, GetterTree } from 'vuex';

Vue.use(Vuex);

const MENU_CLOSE = 'MENU_CLOSE';
const MENU_OPEN = 'MENU_OPEN';
const MENU_FREEZE = 'MENU_FREEZE';
const MENU_UNFREEZE = 'MENU_UNFREEZE';


export class State {
  public isOpen: boolean = false;
  public isFrozen: boolean = false;
}

const mutations = {
  [MENU_CLOSE](state) {
    state.isOpen = false;
  },
  [MENU_OPEN](state) {
    state.isOpen = true;
  },
  [MENU_FREEZE](state) {
    state.isFrozen = true;
  },
  [MENU_UNFREEZE](state) {
    state.isFrozen = false;
  }
} as MutationTree<State>;

const actions = {
  open({ commit }): any {
    commit(MENU_OPEN);
  },
  close({ commit }): any {
    commit(MENU_CLOSE);
  },
  freeze({ commit }): any {
    commit(MENU_FREEZE);
  },
  unfreeze({ commit }): any {
    commit(MENU_UNFREEZE);
  }
} as ActionTree<State, any>;

export const menu = {
  namespaced: true,
  state: new State(),
  mutations,
  actions
};

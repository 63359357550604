import Vue from 'vue';
import axios from 'axios';
import Toasted from 'vue-toasted';
import PortalVue from 'portal-vue'
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import { APIError, ValidationError } from './errors';
import { FontAwesomeIcon } from './font-awesome';
import BaseIcon from '@/components/BaseIcon.vue'
import IconCross from '@/components/icons/IconCross.vue'
import IconThreeDots from '@/components/icons/IconThreeDots.vue'
import CategoryDropdownList from '@/components/category/DropdownList.vue';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('base-icon', BaseIcon);
Vue.component('icon-cross', IconCross);
Vue.component('icon-three-dots', IconThreeDots);

// Because these components are nested, declare them here to avoid confusion when resolving the components.
// See: https://github.com/vuejs/vue/issues/7835
Vue.component('CategoryDropdownList', CategoryDropdownList)

Vue.config.productionTip = false;

Vue.use(Toasted, {
  iconPack: 'fontawesome',
  duration: 5000
});
Vue.use(PortalVue);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

axios.interceptors.response.use(
  response => response,
  error => {
    if ('response' in error) {
      try {
        const {
          response: {
            data:
            { status, title, detail, validation }
          }
        } = error;
        let apiError;
        if (validation !== undefined) {
          apiError = new ValidationError(status, title, detail, validation);
        } else {
          apiError = new APIError(status, title, detail);
        }
        // Each caller can decide what to do, for instance show a toast
        // Vue.toasted.error(`Error during the request: ${title}`);
        return Promise.reject(apiError);
      } catch (e) {
        if (e instanceof TypeError) {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);
axios.defaults.withCredentials = true;

import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree } from 'vuex';
import axios from 'axios';
import jwt_decode from "jwt-decode";

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;
const USERINFO_URL = process.env.VUE_APP_AUTH_USERINFO_PATH;

const USER_FETCH_ME_REQUEST = 'USER_FETCH_ME_REQUEST';
const USER_FETCH_ME_SUCCESS = 'USER_FETCH_ME_SUCCESS';
const USER_FETCH_ME_FAILURE = 'USER_FETCH_ME_FAILURE';
const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export class State {
  public isAuthenticated: boolean = false;
  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public name?: string;
  public nickname?: string;
  public picture?: string;
  public error: boolean = false;
}

const mutations = {
  [USER_FETCH_ME_REQUEST](state) {
    state.isFetching = true;
  },
  [USER_FETCH_ME_SUCCESS](state, payload) {
    state.isAuthenticated = true;
    state.isFetching = false;
    state.name = payload.name;
    state.nickname = payload.nickname;
    state.picture = payload.picture;
  },
  [USER_FETCH_ME_FAILURE](state) {
    state.error = true;
    state.isAuthenticated = false;
    state.isFetching = false;
  },
  [USER_DELETE_REQUEST](state) {
    state.isDeleting = true;
  },
  [USER_DELETE_SUCCESS](state) {
    state.isAuthenticated = false;
    state.isDeleting = false;
    state.name = undefined;
    state.nickname = undefined;
    state.picture = undefined;
  },
  [USER_DELETE_FAILURE](state) {
    state.error = true;
    state.isDeleting = false;
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }): any {
    commit(USER_FETCH_ME_REQUEST);
    return new Promise((resolve, reject) => {
      axios({
        url: `${USERINFO_URL}`,
        withCredentials: true
      })
        .then(response => {
          const auth = response.headers.authorization;
          const jwt_token = auth.substring("Bearer".length + 1)
          const jwt_data = jwt_decode(jwt_token)
          commit(USER_FETCH_ME_SUCCESS, jwt_data);
          return resolve(jwt_token);
        })
        .catch(error => {
          commit(USER_FETCH_ME_FAILURE);
          return reject(error);
        });
    });
  },
  delete({ commit }): any {
    commit(USER_DELETE_REQUEST);
    return new Promise((resolve, reject) => {
      axios({
        url: `${API_URL}/users/delete`,
        method: 'post',
        withCredentials: true
      })
        .then(response => {
          const payload = response;
          commit(USER_DELETE_SUCCESS);
          return resolve(payload);
        })
        .catch(error => {
          commit(USER_DELETE_FAILURE);
          return reject(error);
        });
    });
  }
} as ActionTree<State, any>;

export const user = {
  namespaced: true,
  state: new State(),
  mutations,
  actions
};

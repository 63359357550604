























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  get hasTitleSlot() {
    return !!this.$slots.title;
  }

  get hasFooterSlot() {
    return !!this.$slots.footer;
  }
}


































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DashboardsMenu extends Vue {
  @Prop()
  public to!: string;
  @Prop({ default: false })
  public visible!: boolean;
}

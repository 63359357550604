import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Dashboard, MoveItems } from '@/types';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;

const DASHBOARD_FETCH_REQUEST = 'DASHBOARD_FETCH_REQUEST';
const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
const DASHBOARD_FETCH_FAILURE = 'DASHBOARD_FETCH_FAILURE';
const DASHBOARD_PUT_REQUEST = 'DASHBOARD_PUT_REQUEST';
const DASHBOARD_PUT_SUCCESS = 'DASHBOARD_PUT_SUCCESS';
const DASHBOARD_PUT_FAILURE = 'DASHBOARD_PUT_FAILURE';
const DASHBOARD_DELETE_REQUEST = 'DASHBOARD_DELETE_REQUEST';
const DASHBOARD_DELETE_SUCCESS = 'DASHBOARD_DELETE_SUCCESS';
const DASHBOARD_DELETE_FAILURE = 'DASHBOARD_DELETE_FAILURE';
const DASHBOARD_MOVE = 'DASHBOARD_MOVE';

export class State {
  public dashboards: Dashboard[] = [];
  public isFetching: boolean = true;
  public fetchError: boolean = false;
  public isPuting: boolean = false;
  public putError: boolean = false;
  public isDeleting: boolean = false;
  public deleteError: boolean = false;
  public total: number = 0;
  public totalFiltered: number = 0;
  public count: number = 0;
}

const mutations = {
  [DASHBOARD_FETCH_REQUEST](state) {
    state.fetchError = false;
    state.isFetching = true;
  },
  [DASHBOARD_FETCH_SUCCESS](state, payload: Dashboard[]) {
    state.fetchError = false;
    state.isFetching = false;
    state.dashboards = payload;
  },
  [DASHBOARD_FETCH_FAILURE](state) {
    state.fetchError = true;
    state.isFetching = false;
  },
  [DASHBOARD_PUT_REQUEST](state) {
    state.isPuting = true;
    state.putError = false;
  },
  [DASHBOARD_PUT_SUCCESS](state, payload: Dashboard) {
    state.isPuting = false;
    state.putError = false;
    const index = state.dashboards.findIndex(dashboard => dashboard.id === payload.id);
    Vue.set(state.dashboards, index !== -1 ? index : state.dashboards.length, payload);
  },
  [DASHBOARD_PUT_FAILURE](state) {
    state.isPuting = false;
    state.putError = true;
  },
  [DASHBOARD_DELETE_REQUEST](state) {
    state.isDeleting = true;
    state.deleteError = false;
  },
  [DASHBOARD_DELETE_SUCCESS](state, payload: Dashboard) {
    state.isDeleting = false;
    state.deleteError = false;
    const index = state.dashboards.findIndex(dashboard => dashboard.id === payload.id);
    state.dashboards.splice(index, 1);
  },
  [DASHBOARD_DELETE_FAILURE](state) {
    state.isDeleting = false;
    state.deleteError = true;
  },
  [DASHBOARD_MOVE](state, payload: MoveItems) {
    const index = state.dashboards.findIndex(dashboard => dashboard.id === payload.id);
    const dashboard = state.dashboards.splice(index, 1)[0];
    if (payload.from < payload.to) {
      state.dashboards.filter(dashboard => dashboard.order > payload.from && dashboard.order <= payload.to).forEach((dashboard) => dashboard.order = dashboard.order - 1);
    } else {
      state.dashboards.filter(dashboard => dashboard.order >= payload.to && dashboard.order < payload.from).forEach((dashboard) => dashboard.order = dashboard.order + 1);
    }
    dashboard.order = payload.to;
    state.dashboards.splice(payload.to, 0, dashboard);
    console.log(state.dashboards);
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }): any {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_FETCH_REQUEST);
      axios({
        url: `${API_URL}/dashboards`
      }).then(
        response => {
          const payload: Dashboard[] = response && response.data && response.data.data;
          commit(DASHBOARD_FETCH_SUCCESS, payload);
          resolve(payload);
        },
        error => {
          commit(DASHBOARD_FETCH_FAILURE);
          reject(error);
        }
      );
    });
  },
  put({ commit }, payload: Dashboard): any {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'order', 'configuration']);
      axios({
        url: `${API_URL}/dashboards/${payload.id}`,
        method: 'put',
        data
      }).then(
        response => {
          const dashboard: Dashboard = response && response.data;
          commit(DASHBOARD_PUT_SUCCESS, dashboard);
          resolve(dashboard);
        },
        error => {
          commit(DASHBOARD_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  post({ commit }, payload: Dashboard): any {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'order', 'configuration']);
      axios({
        url: `${API_URL}/dashboards`,
        method: 'post',
        data
      }).then(
        response => {
          const dashboard: Dashboard = response && response.data;
          commit(DASHBOARD_PUT_SUCCESS, dashboard);
          resolve(dashboard);
        },
        error => {
          commit(DASHBOARD_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  delete({ commit }, payload: Dashboard): any {
    return new Promise((resolve, reject) => {
      commit(DASHBOARD_DELETE_REQUEST);
      axios({
        url: `${API_URL}/dashboards/${payload.id}`,
        method: 'delete'
      }).then(
        response => {
          commit(DASHBOARD_DELETE_SUCCESS, payload);
          resolve(response);
        },
        error => {
          commit(DASHBOARD_DELETE_FAILURE);
          reject(error);
        }
      );
    });
  },
  move({ commit, getters, dispatch }, payload: MoveItems): any {
    commit(DASHBOARD_MOVE, payload);
    const dashboard = getters.dashboardById(payload.id);
    dispatch('put', dashboard);
  }
} as ActionTree<State, any>;

const getters = {
  dashboardWithSlug(state): (slug: string) => Dashboard | null {
    return (slug: string): Dashboard | null => {
      const results: Dashboard[] = state.dashboards.filter(dashboard => dashboard.slug === slug);
      return results.length === 1 ? results[0] : null;
    };
  },
  dashboardById(state): (id: number) => Dashboard | null {
    return (id: number): Dashboard | null => {
      const results: Dashboard[] = state.dashboards.filter(dashboard => dashboard.id === id);
      return results.length === 1 ? results[0] : null;
    };
  },
  sortedDashboards(state): Dashboard[] | null {
    const results = state.dashboards.slice(); // Work on a copy
    results.sort((a: Dashboard, b: Dashboard) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
    return results;
  },
  isOperationPending(state): boolean {
    return state.isFetching || state.isPuting || state.isDeleting;
  }
} as GetterTree<State, Dashboard>;

export const dashboards = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Bookmark } from '@/types';
import moment from 'moment';

@Component({ components: {} })
export default class ListItem extends Vue {
  @Prop()
  public bookmark!: Bookmark;

  get dateCreated() {
    return moment(this.bookmark.created).format('Do MMM YYYY');
  }

  get dateUpdated() {
    return moment(this.bookmark.updated).format('Do MMM YYYY');
  }
}

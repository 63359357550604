






































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Dashboard } from '@/types';

@Component({})
export default class DashboardsListItem extends Vue {
  @Prop()
  // @ts-ignore
  private dashboard!: Dashboard;
  @Getter('isOperationPending', { namespace: 'dashboards' })
  public isOperationPending!: boolean;

  protected scrollToTop() {
    window.scrollTo(0, 0);
  }

  /**
   * On mouseOver, show the three dots menu for the hover element.
   * When moving inside a child, the mouseOut will be fired.
   */
  protected mouseOver(event: any) {
    event.stopPropagation();
    event.currentTarget.parentElement.classList.add('is-hover');
  }

  protected mouseOut(event: any) {
    event.stopPropagation();
    event.currentTarget.parentElement.classList.remove('is-hover');
  }
}

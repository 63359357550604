




































































import { Component, Mixins } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import EditMixin, { EVENT } from '@/components/dashboard/EditMixin.vue';
import { Dashboard, DashboardConfiguration, MoveItems, DraggablePosition } from '@/types';
import SlideMenu from '@/components/SlideMenu.vue';
import DirtySaveDialog from '@/components/DirtySaveDialog.vue';
import BaseMenuLoading from '@/components/BaseMenuLoading.vue';
import MenuItem from '@/components/MenuItem.vue';
import DashboardsList from './List.vue';
import DashboardEditForm from '@/components/dashboard/EditForm.vue';
import Draggable from '@/components/Draggable.vue';

@Component({
  components: {
    BaseMenuLoading,
    MenuItem,
    DashboardsList,
    DashboardEditForm,
    DirtySaveDialog,
    Draggable,
    SlideMenu,
  },
})
export default class DashboardsMenu extends Mixins(EditMixin) {
  @Action('move', { namespace: 'dashboards' })
  public move: any;
  @State('isFetching', { namespace: 'dashboards' })
  public isFetching!: Dashboard[];
  @State('fetchError', { namespace: 'dashboards' })
  public fetchError!: Dashboard[];
  @Getter('sortedDashboards', { namespace: 'dashboards' })
  public dashboards!: Dashboard[];
  @Getter('dashboardById', { namespace: 'dashboards' })
  public dashboardById!: any;
  @Action('close', { namespace: 'menu' })
  public closeMenu!: any;
  @Action('freeze', { namespace: 'menu' })
  public freezeMenu!: any;
  @Action('unfreeze', { namespace: 'menu' })
  public unfreezeMenu!: any;
  private showSlide: boolean = false;
  private showDirtySaveDialog: boolean = false;
  private draggableOptions = {
    draggable: `li.draggable`,
    distance: 10,
    mirror: {
      constrainDimensions: true,
    },
  };
  private contextualMenuDashboardSelected?: Dashboard;

  get dashboardsList() {
    return this.dashboards;
  }

  protected async moved(event: any) {
    const dashboardId = event.from.getAttribute('data-id');
    const toDashboardId = event.to.getAttribute('data-id');
    if (!dashboardId || !toDashboardId) {
      return;
    }

    const dashboard: Dashboard = this.dashboardById(parseInt(dashboardId));
    const toDashboard: Dashboard = this.dashboardById(parseInt(toDashboardId));

    let position: number | null = toDashboard.order;
    if (position && event.position == DraggablePosition.after && dashboard.order > position) {
      // If it was dropped after a dashboard, coming from a bigger position, add 1.
      // i.e.: dashboard 4 was dropped after 2. Position should be 3.
      position += 1;
    } else if (event.position == DraggablePosition.before && dashboard.order < position) {
      // If it was dropped before a dashboard, coming from a lower position, remove 1.
      // I.e.: dashboard 1 was dropped before 3. Position should be 2.
      position -= 1;
    }

    this.move({
      id: dashboard.id,
      from: dashboard.order,
      to: position,
    } as MoveItems);
  }

  public addDashboard() {
    this.dashboard = {
      configuration: {
        panels: [],
      } as DashboardConfiguration,
    } as Dashboard;
    this.editionStarted();
    this.showSlide = true;
  }

  public editDashboard(dashboard: Dashboard) {
    if (this.showSlide) {
      return;
    }
    this.mouseLeaveContextualMenu();
    this.dashboard = dashboard;
    this.editionStarted();
    this.showSlide = true;
  }

  public closeSlide(checkDirty: boolean = true) {
    if (checkDirty && this.dirty) {
      this.showDirtySaveDialog = true;
      return;
    }
    this.cancel();
    this.showSlide = false;
    this.showDirtySaveDialog = false;
  }

  public cancelClose() {
    this.showDirtySaveDialog = false;
  }

  protected editEvent(event: string) {
    switch (event) {
      case EVENT.EDIT_SAVE_SUCCESS:
        this.$toasted.success(`Dashboard ${this.dashboard ? this.dashboard.name : ''} saved`);
        this.showSlide = false;
        this.showDirtySaveDialog = false;
        break;
      case EVENT.EDIT_SAVE_FAILURE:
        this.$toasted.error(
          `Could not save dashboard ${this.dashboard ? this.dashboard.name : ''}`
        );
        break;
      case EVENT.EDIT_DELETE_SUCCESS:
        this.$toasted.success(`Dashboard ${this.dashboard ? this.dashboard.name : ''} deleted`);
        this.showSlide = false;
        this.showDirtySaveDialog = false;
        break;
      case EVENT.EDIT_DELETE_FAILURE:
        this.$toasted.error(
          `Could not delete dashboard ${this.dashboard ? this.dashboard.name : ''}`
        );
        break;
      case EVENT.EDIT_CANCELED:
        this.$emit('close');
        this.showSlide = false;
        this.showDirtySaveDialog = false;
    }
  }

  protected openContextualMenu(event: any, dashboard: Dashboard) {
    this.freezeMenu();
    this.contextualMenuDashboardSelected = dashboard;
    let topPosition = 0;
    let headerHeight = 0;
    // Get the hovered element boundingRect before displaying the child
    // to get the correct height
    const boundingRect = event.currentTarget.getBoundingClientRect();

    // Show the child (display block) so we can get its height
    const childEl: HTMLElement | null = document.querySelector('.menu-dashboard .contextual-menu');
    if (childEl == null) {
      return;
    }
    childEl.style.display = 'block';

    // Compute the child top position, based on the current element boundingRect and the child height
    topPosition = this.calculateTop(boundingRect, childEl.offsetHeight);
    topPosition = Math.max(0, Math.floor(topPosition) - headerHeight);
    let left = this.calculateLeft(event.currentTarget.getBoundingClientRect());
    childEl.style.left = `${left}px`;
    childEl.style.top = `${topPosition}px`;
  }

  protected calculateTop(boundingRect: any, outerHeight: any) {
    const windowHeight = window.innerHeight;
    const bottomOverflow = windowHeight - (boundingRect.top + outerHeight);

    return bottomOverflow < 0
      ? boundingRect.top - outerHeight + boundingRect.height - 4
      : boundingRect.top + 4;
  }

  protected calculateLeft(boundingRect: any) {
    return boundingRect.right - 20;
  }

  protected mouseLeaveContextualMenu() {
    this.contextualMenuDashboardSelected = undefined;
    const childEl: HTMLElement | null = document.querySelector('.menu-dashboard .contextual-menu');
    if (childEl == null) {
      return;
    }
    childEl.style.display = 'none';
    this.unfreezeMenu();
  }
}






























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { SmartTag, FieldsErrors } from '@/types';

@Component
export default class SmartTagEdit extends Vue {
  @Prop()
  public smartTag!: SmartTag;
  @Prop()
  public error?: boolean;
  @Prop()
  public fieldsErrors?: FieldsErrors;
  @State('smartTags', { namespace: 'smartTags' })
  public smartTags!: SmartTag[];

  public mounted() {
    const name: any = this.$refs.name;
    if (name instanceof HTMLInputElement) {
      name.focus();
    }
  }

  get inputTags() {
    return (this.smartTag && this.smartTag.tags.join(', ')) || '';
  }

  set inputTags(newValue: string) {
    this.smartTag.tags = newValue.split(', ');
  }
}









import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import Navbar from '@/components/TheNavbar.vue';

@Component({
  components: {
    Navbar,
  },
})
export default class App extends Vue {
  @State('isAuthenticated', { namespace: 'user' })
  private isAuthenticated!: boolean;
  @Action('fetch', { namespace: 'categories' })
  public fetchCategories: any;
  @Action('fetch', { namespace: 'bookmarks' })
  public fetchBookmarks: any;
  @Action('fetch', { namespace: 'dashboards' })
  public fetchDashboards: any;

  @Watch('isAuthenticated')
  protected async authenticatedChanged() {
    if (this.isAuthenticated) {
      // Once authenticated, fetch all the data.
      try {
        await Promise.all([
          this.fetchCategories({ limit: 5000 }),
          this.fetchDashboards(),
          this.fetchBookmarks({ limit: 5000 }),
        ]);
      } catch (error) {
        console.error('Could not load data.');
      }
    }
  }
}

















import { Component, Mixins } from 'vue-property-decorator';
import { Bookmark } from '../types';
import EditForm from '@/components/bookmark/EditForm.vue';
import EditMixin, { EVENT } from '@/components/bookmark/EditMixin.vue';

@Component({
  components: {
    EditForm
  }
})
export default class BookmarkAdd extends Mixins(EditMixin) {
  public beforeMount() {
    this.editionStarted();
  }

  protected editionStarted() {
    this.editingBookmark = Object.assign({}, this.bookmarkTemplate);
  }

  get bookmarkTemplate(): Bookmark {
    const bookmarkTemplate = {} as Bookmark;
    bookmarkTemplate.tags = new Array();
    // @ts-ignore
    bookmarkTemplate.url = this.$route.query.url;
    // @ts-ignore
    bookmarkTemplate.title = this.$route.query.title;
    return bookmarkTemplate;
  }

  protected editEvent(event: string) {
    switch (event) {
      case EVENT.EDIT_SAVE_SUCCESS:
        window.close();
        break;
      case EVENT.EDIT_CANCELED:
        window.close();
        break;
    }
  }
}

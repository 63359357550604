






















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { SmartTag, Category } from '@/types';

@Component({})
export default class Bookmarks extends Vue {
  @Prop()
  public currentSmartTag?: SmartTag;
  @Prop()
  public currentCategory?: Category;
  @Prop()
  public search!: any;
  @Prop()
  public selectLayout!: any;
  @Prop()
  public addBookmark!: any;
  @Prop()
  public sortBy!: any;
  @Prop({ default: '' })
  public initialSearchText!: string;
  public searchText: string = '';

  public mounted() {
    this.searchText = this.initialSearchText;
  }
}

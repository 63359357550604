






































































import { Component, Vue, Watch, Mixins } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import { mapGetters } from 'vuex';
import { Category, DraggableMovedEvent, DraggablePosition, MoveItems } from '@/types';
import SlideMenu from '@/components/SlideMenu.vue';
import BaseMenuLoading from '@/components/BaseMenuLoading.vue';
import EditMixin, { EVENT } from '@/components/category/EditMixin.vue';
import CategoryEditForm from '@/components/category/EditForm.vue';
import MenuItem from '@/components/MenuItem.vue';
import { Plugins } from '@shopify/draggable';
import Draggable from '@/components/Draggable.vue';

@Component({
  components: {
    BaseMenuLoading,
    MenuItem,
    CategoryEditForm,
    SlideMenu,
    Draggable,
  },
  computed: {
    ...mapGetters('categories', {
      rootCategories: 'rootCategories', // <- for runtime
    }),
  },
})
export default class CategoriesMenu extends Mixins(EditMixin) {
  @State('categories', { namespace: 'categories' })
  public categories!: Category[];
  @State('isFetching', { namespace: 'categories' })
  public isFetching!: boolean[];
  @State('isPuting', { namespace: 'categories' })
  public isPuting!: boolean;
  @State('fetchError', { namespace: 'categories' })
  public fetchError!: boolean[];
  @Action('move', { namespace: 'categories' })
  public move!: any;
  @Action('close', { namespace: 'menu' })
  public closeMenu!: any;
  @Action('freeze', { namespace: 'menu' })
  public freezeMenu!: any;
  @Action('unfreeze', { namespace: 'menu' })
  public unfreezeMenu!: any;
  @Getter('isOperationPending', { namespace: 'categories' })
  public isOperationPending!: boolean;
  public rootCategories?: Category[] | null;
  @Getter('categoryById', { namespace: 'categories' })
  public categoryById: any;
  private showSlide: boolean = false;
  private showDirtySaveDialog: boolean = false;
  private draggableOptions = {
    draggable: `li.draggable`,
    //delay: 200,
    distance: 10,
    mirror: {
      constrainDimensions: true,
    },
    plugins: [Plugins.ResizeMirror],
  };
  private contextualMenuCategorySelected?: Category;

  public addCategory() {
    this.category = {} as Category;
    this.editionStarted();
    this.showSlide = true;
  }

  public editCategory(category: Category) {
    if (this.showSlide) {
      return;
    }
    this.unfreezeMenu();
    this.closeMenu();
    this.mouseLeaveContextualMenu();
    this.category = category;
    this.editionStarted();
    this.showSlide = true;
  }

  public closeSlide(checkDirty: boolean = true) {
    if (checkDirty && this.dirty) {
      this.showDirtySaveDialog = true;
      return;
    }
    this.cancel();
    this.showSlide = false;
    this.showDirtySaveDialog = false;
  }

  public cancelClose() {
    this.showDirtySaveDialog = false;
  }

  protected editEvent(event: string) {
    switch (event) {
      case EVENT.EDIT_SAVE_SUCCESS:
        this.$toasted.success(`Category ${this.category ? this.category.name : ''} saved`);
        this.showSlide = false;
        this.showDirtySaveDialog = false;
        break;
      case EVENT.EDIT_SAVE_FAILURE:
        this.$toasted.error(`Could not save category ${this.category ? this.category.name : ''}`);
        break;
      case EVENT.EDIT_DELETE_SUCCESS:
        this.$toasted.success(`Category ${this.category ? this.category.name : ''} deleted`);
        this.showSlide = false;
        this.showDirtySaveDialog = false;
        break;
      case EVENT.EDIT_DELETE_FAILURE:
        this.$toasted.error(`Could not delete category ${this.category ? this.category.name : ''}`);
        break;
      case EVENT.EDIT_CANCELED:
        this.$emit('close');
        this.showSlide = false;
        this.showDirtySaveDialog = false;
    }
  }

  protected moved(event: DraggableMovedEvent) {
    const categoryId = event.from.getAttribute('data-id');
    const toCategoryId = event.to.getAttribute('data-id');

    if (!categoryId || !toCategoryId) {
      return;
    }

    const category: Category = this.categoryById(parseInt(categoryId));
    const toCategory: Category = this.categoryById(parseInt(toCategoryId));

    let newParentId = null;
    if (event.position == DraggablePosition.inside) {
      newParentId = toCategory.id;
    } else if (category.parent_id != toCategory.parent_id) {
      newParentId = toCategory.parent_id;
    }

    let position: number | null = toCategory.order;
    if (event.position == DraggablePosition.inside) {
      // In case it was dropped on a category (not directly in its children, or on a category that had no children yet),
      // keep position = null. It should be added at the end of all the existing children if any.
      position = null;
    } else if (
      (event.position == DraggablePosition.after && newParentId) ||
      (event.position == DraggablePosition.after && category.order > position)
    ) {
      // If it was dropped after a category, coming from a bigger position, add 1.
      // i.e.: category 4 was dropped after 2. Position should be 3.
      position += 1;
    } else if (event.position == DraggablePosition.before && category.order < position) {
      // If it was dropped before a category, coming from a lower position, remove 1.
      // I.e.: category 1 was dropped before 3. Position should be 2.
      position -= 1;
    }

    this.move({
      id: category.id,
      from: category.order,
      to: position,
      new_parent_id: newParentId,
    } as MoveItems);
  }

  protected openContextualMenu(event: any, category: Category) {
    this.freezeMenu();
    this.contextualMenuCategorySelected = category;
    let topPosition = 0;
    let headerHeight = 0;
    // Get the hovered element boundingRect before displaying the child
    // to get the correct height
    const boundingRect = event.currentTarget.getBoundingClientRect();

    // Show the child (display block) so we can get its height
    const childEl: HTMLElement | null = document.querySelector('.menu-category .contextual-menu');
    if (childEl == null) {
      return;
    }
    childEl.style.display = 'block';

    // Compute the child top position, based on the current element boundingRect and the child height
    topPosition = this.calculateTop(boundingRect, childEl.offsetHeight);
    topPosition = Math.max(0, Math.floor(topPosition) - headerHeight);
    let left = this.calculateLeft(event.currentTarget.getBoundingClientRect());
    childEl.style.left = `${left}px`;
    childEl.style.top = `${topPosition}px`;
  }

  protected calculateTop(boundingRect: any, outerHeight: any) {
    const windowHeight = window.innerHeight;
    const bottomOverflow = windowHeight - (boundingRect.top + outerHeight);

    return bottomOverflow < 0
      ? boundingRect.top - outerHeight + boundingRect.height - 4
      : boundingRect.top + 4;
  }

  protected calculateLeft(boundingRect: any) {
    return boundingRect.right - 20;
  }

  protected mouseLeaveContextualMenu() {
    this.contextualMenuCategorySelected = undefined;
    const childEl: HTMLElement | null = document.querySelector('.menu-category .contextual-menu');
    if (childEl == null) {
      return;
    }
    childEl.style.display = 'none';
    this.unfreezeMenu();
  }
}

import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { Category, CategoryFilters, MoveItems } from '@/types';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;

const CATEGORY_FETCH_REQUEST = 'CATEGORY_FETCH_REQUEST';
const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS';
const CATEGORY_FETCH_FAILURE = 'CATEGORY_FETCH_FAILURE';
const CATEGORY_PUT_REQUEST = 'CATEGORY_PUT_REQUEST';
const CATEGORY_PUT_SUCCESS = 'CATEGORY_PUT_SUCCESS';
const CATEGORY_PUT_FAILURE = 'CATEGORY_PUT_FAILURE';
const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
const CATEGORY_DELETE_FAILURE = 'CATEGORY_DELETE_FAILURE';
const CATEGORY_FILTER = 'CATEGORY_FILTER';
const CATEGORY_MOVE = 'CATEGORY_MOVE';


export class State {
  public categories: Category[] = [];
  public rootCategory?: Category;
  public isFetching: boolean = true;
  public isUpdating: boolean = false;
  public fetchError: boolean = false;
  public isPuting: boolean = false;
  public putError: boolean = false;
  public isDeleting: boolean = false;
  public deleteError: boolean = false;
  public total: number = 0;
  public totalFiltered: number = 0;
  public count: number = 0;
  public filters: CategoryFilters = {} as CategoryFilters;
}

function removeFromParent(state: State, mcategory: Category) {
  const children = state.categories.filter((cat) => cat.parent_id == mcategory.parent_id);
  if (!children || children.length <= 0) {
    return;
  }

  // Update children
  children.filter(cat => cat.order > mcategory.order).forEach((cat) => cat.order = cat.order - 1);
  children.forEach((child) => {
    Vue.set(state.categories, state.categories.findIndex(category => category.id === child.id), child);
  })
}

function addToParent(state: State, mcategory: Category, parentCategory: Category, position: number | null) {
  const children = state.categories.filter((cat) => cat.parent_id == parentCategory.id);
  if (position && children) {
    children.filter(cat => cat.order >= position).forEach((cat) => cat.order = cat.order + 1);
  }

  mcategory.order = position != null ? position : children.length;

  // Update category
  const mcategoryIndex = state.categories.findIndex(category => category.id === mcategory.id);
  mcategory.parent_id = parentCategory.id;
  Vue.set(state.categories, mcategoryIndex, mcategory);

  // Update children
  children.forEach((child) => {
    Vue.set(state.categories, state.categories.findIndex(category => category.id === child.id), child);
  })
}

function moveInsideParent(state: State, mcategory: Category, position: number) {
  const children = state.categories.filter((cat) => cat.parent_id == mcategory.parent_id);
  if (!children || children.length <= 0) {
    return;
  }

  if (mcategory.order < position) {
    children.filter(cat => cat.order > mcategory.order && cat.order <= position).forEach((cat) => cat.order = cat.order - 1);
  } else {
    children.filter(cat => cat.order >= position && cat.order < mcategory.order).forEach((cat) => cat.order = cat.order + 1);
  }
  mcategory.order = position;

  // Update category
  const mcategoryIndex = state.categories.findIndex(category => category.id === mcategory.id);
  Vue.set(state.categories, mcategoryIndex, mcategory);

  // Update children
  children.forEach((child) => {
    Vue.set(state.categories, state.categories.findIndex(category => category.id === child.id), child);
  })
}

const mutations = {
  [CATEGORY_FETCH_REQUEST](state) {
    state.fetchError = false;
    state.isFetching = true;
  },
  [CATEGORY_FETCH_SUCCESS](state, payload: Category[]) {
    state.rootCategory = payload.find((cat) => !cat.parent_id);
    state.categories = payload;
    state.fetchError = false;
    state.isFetching = false;
  },
  [CATEGORY_FETCH_FAILURE](state) {
    state.fetchError = true;
    state.isFetching = false;
  },
  [CATEGORY_PUT_REQUEST](state) {
    state.isPuting = true;
    state.putError = false;
  },
  [CATEGORY_PUT_SUCCESS](state, payload: Category) {
    state.isPuting = false;
    state.putError = false;
    const index = state.categories.findIndex(category => category.id === payload.id);
    Vue.set(state.categories, index !== -1 ? index : state.categories.length, payload);
  },
  [CATEGORY_PUT_FAILURE](state) {
    state.isPuting = false;
    state.putError = true;
  },
  [CATEGORY_DELETE_REQUEST](state) {
    state.isDeleting = true;
    state.deleteError = false;
  },
  [CATEGORY_DELETE_SUCCESS](state, payload: Category) {
    state.isDeleting = false;
    state.deleteError = false;
    const index = state.categories.findIndex(category => category.id === payload.id);
    state.categories.splice(index, 1);
  },
  [CATEGORY_DELETE_FAILURE](state) {
    state.isDeleting = false;
    state.deleteError = true;
  },
  [CATEGORY_FILTER](state, payload: CategoryFilters) {
    state.filters = Object.assign({}, payload);
  },
  [CATEGORY_MOVE](state, move: MoveItems) {
    const category = state.categories.find((cat) => cat.id == move.id);
    if (!category) return;
    if (!move.new_parent_id) {
      moveInsideParent(state, category, move.to);
      return;
    }
    removeFromParent(state, category);
    const parentCategory = state.categories.find((cat) => cat.id == move.new_parent_id);
    if (!parentCategory) return;
    addToParent(state, category, parentCategory, move.to);
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }, params): any {
    return new Promise((resolve, reject) => {
      commit(CATEGORY_FETCH_REQUEST);
      axios({
        url: `${API_URL}/categories`,
        params
      }).then(
        response => {
          const payload: Category = response && response.data && response.data.data;
          commit(CATEGORY_FETCH_SUCCESS, payload);
          resolve(payload);
        },
        error => {
          commit(CATEGORY_FETCH_FAILURE);
          reject(error);
        }
      );
    });
  },
  put({ commit }, payload: Category): any {
    return new Promise((resolve, reject) => {
      commit(CATEGORY_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'order', 'parent_id']);
      axios({
        url: `${API_URL}/categories/${payload.id}`,
        method: 'put',
        data
      }).then(
        response => {
          const category: Category = response && response.data
          commit(CATEGORY_PUT_SUCCESS, category);
          resolve(category);
        },
        error => {
          commit(CATEGORY_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  post({ commit }, payload: Category): any {
    return new Promise((resolve, reject) => {
      commit(CATEGORY_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'parent_id']);
      axios({
        url: `${API_URL}/categories`,
        method: 'post',
        data
      }).then(
        response => {
          const category: Category = response && response.data;
          commit(CATEGORY_PUT_SUCCESS, category);
          resolve(category);
        },
        error => {
          commit(CATEGORY_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  delete({ commit }, payload: Category): any {
    return new Promise((resolve, reject) => {
      commit(CATEGORY_DELETE_REQUEST);
      axios({
        url: `${API_URL}/categories/${payload.id}`,
        method: 'delete'
      }).then(
        response => {
          commit(CATEGORY_DELETE_SUCCESS, payload);
          resolve(response);
        },
        error => {
          commit(CATEGORY_DELETE_FAILURE);
          reject(error);
        }
      );
    });
  },
  filter({ commit }, payload: CategoryFilters): any {
    commit(CATEGORY_FILTER, payload);
  },
  move({ commit, dispatch, getters }, categoryMove: MoveItems): any {
    commit(CATEGORY_MOVE, categoryMove);
    const category = getters.categoryById(categoryMove.id);
    dispatch('put', category);
  }
} as ActionTree<State, any>;

const getters = {
  rootCategories(state, mgetters): Category[] {
    return mgetters.getRootCategories(state.categories);
  },
  getRootCategories: (state, _getters, _rootState) => () => {
    // Can be called as a method, using @Getter
    if (state.rootCategory == undefined) {
      return [] as Category[];
    }
    let results = state.categories.slice();
    if (state.rootCategory === undefined) {
      return results;
    }
    // @ts-ignore
    return results.filter(category => category.parent_id === state.rootCategory.id).sort((a, b) => a.order - b.order);
  },
  categoryWithSlug(state): (slug: string) => Category | null {
    return (slug: string): Category | null => {
      const results: Category[] = state.categories.filter(category => category.slug === slug);
      return results.length === 1 ? results[0] : null;
    };
  },
  categoryById(state): (id: number) => Category | null {
    return (id: number): Category | null => {
      const results: Category[] = state.categories.filter(category => category.id === id);
      return results.length === 1 ? results[0] : null;
    };
  },
  filteredCategories(state, mgetters): Category[] | null {
    return mgetters.getFilteredCategories(state.filters);
  },
  getFilteredCategories: state => (filters: CategoryFilters) => {
    // Can be called as a method, using @Getter
    let results = state.categories.slice(); // Work on a copy

    // Filters
    if (filters.search !== undefined) {
      results = results.filter(category => {
        return (
          // @ts-ignore
          category.name.toLowerCase().includes(filters.search) ||
          // @ts-ignore
          category.slug.toLowerCase().includes(filters.search)
        );
      });
    }

    // Pagination and ordering
    state.totalFiltered = results.length;
    if (filters.page) {
      results = results.slice((filters.page - 1) * filters.limit, filters.page * filters.limit);
    }
    return results;
  },
  isOperationPending(state): boolean {
    return state.isFetching || state.isPuting || state.isDeleting, state.isUpdating;
  },
  hasChildren(state): (category: Category) => boolean {
    return (category: Category): boolean => {
      return state.categories.some((cat) => cat.parent_id == category.id);
    };
  },
  children(state): (category: Category) => Category[] | null {
    return (category: Category): Category[] | null => {
      // @ts-ignore
      return state.categories.filter(cat => cat.parent_id === category.id).sort((a, b) => a.order - b.order);
    }
  },
} as GetterTree<State, Category>;

export const categories = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};

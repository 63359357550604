
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Dashboard, DashboardConfiguration, FieldsErrors } from '@/types';
import { APIError, ValidationError } from '@/errors';
import _ from 'lodash';

export const EVENT = {
  EDIT_SAVE_SUCCESS: 'EDIT_SAVE_SUCCESS',
  EDIT_SAVE_FAILURE: 'EDIT_SAVE_FAILURE',
  EDIT_DELETE_SUCCESS: 'EDIT_DELETE_SUCCESS',
  EDIT_DELETE_FAILURE: 'EDIT_DELETE_FAILURE',
  EDIT_CANCELED: 'EDIT_CANCELED'
};

@Component({})
export default class EditMixin extends Vue {
  @Action('delete', { namespace: 'dashboards' })
  public delete: any;
  @Action('put', { namespace: 'dashboards' })
  public put: any;
  @Action('post', { namespace: 'dashboards' })
  public post: any;
  protected saveError: boolean = false;
  protected saveFieldsErrors: FieldsErrors = {};
  protected dashboard: Dashboard = {} as Dashboard;
  protected dirty: boolean = false;
  private originalDashboard?: Dashboard;
  private unwatch: any = () => {};

  // @ts-ignore
  protected editEvent(event: string, payload: any) {
    // Can be overrided
  }

  protected editionStarted() {
    this.originalDashboard = _.cloneDeep(this.dashboard);
    this.dirty = false;
    this.unwatch = this.$watch(
      'dashboard',
      () => {
        this.dirty = true;
      },
      { deep: true }
    );
  }

  protected remove() {
    if (this.dashboard !== undefined) {
      this.delete(this.dashboard)
        .then(() => {
          this.unwatch();
          this.dirty = false;
          this.editEvent(EVENT.EDIT_DELETE_SUCCESS, null);
        })
        .catch((error: APIError) => {
          this.editEvent(EVENT.EDIT_DELETE_FAILURE, error);
        });
    }
  }

  protected async save() {
    if (!this.dashboard) {
      return;
    }
    this.saveError = false;
    this.saveFieldsErrors = {};
    try {
      if (this.dashboard.id !== undefined) {
        if (this.dashboard.configuration === null) {
          this.dashboard.configuration = {
            panels: []
          } as DashboardConfiguration;
        }
        await this.put(this.dashboard);
      } else {
        await this.post(this.dashboard);
      }
      this.unwatch();
      this.dirty = false;
      this.editEvent(EVENT.EDIT_SAVE_SUCCESS, this.dashboard);
    } catch (error) {
      this.saveError = true;
      if (error instanceof ValidationError && error.validation !== undefined) {
        this.saveFieldsErrors = error.validation;
      }
      this.editEvent(EVENT.EDIT_SAVE_FAILURE, error);
    }
  }

  protected cancel() {
    this.unwatch();
    this.dirty = false;
    Object.assign(this.dashboard, this.originalDashboard);
    this.saveError = false;
    this.saveFieldsErrors = {};
    this.editEvent(EVENT.EDIT_CANCELED, null);
  }
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { DraggableMovedEvent, DraggablePosition } from '@/types';
import { Draggable as shopifyDraggable, Plugins } from '@shopify/draggable';

@Component({})
export default class Draggable extends Vue {
  @Prop()
  public tag?: string;
  @Prop()
  public containers!: string;
  @Prop({ default: false })
  public allowDropInside!: boolean;
  @Prop()
  public options!: object;
  private currentOver?: HTMLElement;
  private currentPosition?: DraggablePosition;
  private draggable?: shopifyDraggable;
  private dragTarget?: any;

  mounted() {
    const containers = document.querySelectorAll(this.containers);
    this.draggable = new shopifyDraggable(containers, this.options);
    this.draggable.on('drag:start', (event) => {
      event.source.querySelectorAll('ul').forEach((el) => (el.style.display = 'none'));
    });
    this.draggable.on('drag:move', (event) => {
      if (this.currentOver == undefined) {
        return;
      }
      var bounding = this.currentOver.getBoundingClientRect();
      // Define the middle of the boundingClientRect, to be able to drop before, after or inside
      // (add the corresponding CSS as indications)
      const middleTop = bounding.y + bounding.height * 0.25;
      const middleBottom = bounding.y + bounding.height * 0.75;
      if (event.sensorEvent.clientY > middleBottom) {
        this.hoverBottom();
        this.currentPosition = DraggablePosition.after;
      } else if (event.sensorEvent.clientY < middleTop) {
        this.hoverTop();
        this.currentPosition = DraggablePosition.before;
      } else if (this.allowDropInside) {
        this.hoverMiddle();
        this.currentPosition = DraggablePosition.inside;
      }
    });
    this.draggable.on('drag:over', (event) => {
      if (event.source != event.over) {
        this.currentOver = event.over;
      }
    });
    this.draggable.on('drag:out', () => {
      this.resetClasses();
    });
    this.draggable.on('drag:stop', (event) => {
      this.resetClasses();
      this.$emit('moved', {
        from: event.originalSource,
        to: this.currentOver,
        position: this.currentPosition,
      } as DraggableMovedEvent);
      this.currentOver = undefined;
    });
  }

  protected resetClasses() {
    document.querySelectorAll('.hover-top').forEach((el) => el.classList.remove('hover-top'));
    document.querySelectorAll('.hover-bottom').forEach((el) => el.classList.remove('hover-bottom'));
    document.querySelectorAll('.hover-middle').forEach((el) => el.classList.remove('hover-middle'));
  }

  protected hoverTop() {
    if (!this.currentOver) return;
    this.currentOver.classList.remove('hover-bottom');
    this.currentOver.classList.remove('hover-middle');
    this.currentOver.classList.add('hover-top');
  }

  protected hoverBottom() {
    if (!this.currentOver) return;
    this.currentOver.classList.add('hover-bottom');
    this.currentOver.classList.remove('hover-middle');
    this.currentOver.classList.remove('hover-top');
  }

  protected hoverMiddle() {
    if (!this.currentOver) return;
    this.currentOver.classList.remove('hover-bottom');
    this.currentOver.classList.add('hover-middle');
    this.currentOver.classList.remove('hover-top');
  }

  updated() {}

  render(createElement: any) {
    return createElement(this.tag || this.$el, this.$slots.default);
  }

  beforeDestroy() {
    if (this.draggable) {
      this.draggable.destroy();
    }
  }
}














































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import draggable from 'vuedraggable';
import { Dashboard, DashboardConfiguration, DashboardPanel, FieldsErrors } from '@/types';
import BaseConfirmDialog from '@/components/BaseConfirmDialog.vue';
import PanelEditField from './PanelEditField.vue';

@Component({
  components: {
    draggable,
    BaseConfirmDialog,
    PanelEditField
  }
})
export default class DashboardEdit extends Vue {
  @Prop()
  public dashboard!: Dashboard;
  @State('dashboards', { namespace: 'dashboards' })
  public dashboards!: Dashboard[];
  @Prop()
  public error?: boolean;
  @Prop()
  public fieldsErrors?: FieldsErrors;
  protected showDeleteConfirmDialog: boolean = false;

  get configurationFieldsError() {
    return (
      this.fieldsErrors && 'configuration' in this.fieldsErrors && this.fieldsErrors.configuration
    );
  }

  get jsonConfiguration() {
    return (
      (this.dashboard &&
        this.dashboard.configuration &&
        JSON.stringify(this.dashboard.configuration)) ||
      ''
    );
  }

  get panels() {
    return this.dashboard && this.dashboard.configuration && this.dashboard.configuration.panels;
  }

  set panels(test: any) {
    console.log('Setting panels');
    console.log(test);
  }

  public mounted() {
    const name: any = this.$refs.name;
    if (name instanceof HTMLInputElement) {
      name.focus();
    }
  }

  public addPanel() {
    this.dashboard.configuration.panels.push({
      // We add all the properties we need, otherwise they won't be reactive (Vue cannot track added/removed properties)
      id: this.dashboard.configuration.panels.length,
      title: '',
      category_id: undefined
    } as DashboardPanel);
  }

  public removePanel(panel: DashboardPanel) {
    const index = this.dashboard.configuration.panels.findIndex(aPanel => aPanel.id === panel.id);
    this.dashboard.configuration.panels.splice(index, 1);
  }
}

import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { SmartTag } from '@/types';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;

const SMART_TAG_FETCH_REQUEST = 'SMART_TAG_FETCH_REQUEST';
const SMART_TAG_FETCH_SUCCESS = 'SMART_TAG_FETCH_SUCCESS';
const SMART_TAG_FETCH_FAILURE = 'SMART_TAG_FETCH_FAILURE';
const SMART_TAG_PUT_REQUEST = 'SMART_TAG_PUT_REQUEST';
const SMART_TAG_PUT_SUCCESS = 'SMART_TAG_PUT_SUCCESS';
const SMART_TAG_PUT_FAILURE = 'SMART_TAG_PUT_FAILURE';
const SMART_TAG_DELETE_REQUEST = 'SMART_TAG_DELETE_REQUEST';
const SMART_TAG_DELETE_SUCCESS = 'SMART_TAG_DELETE_SUCCESS';
const SMART_TAG_DELETE_FAILURE = 'SMART_TAG_DELETE_FAILURE';

export class State {
  public smartTags: SmartTag[] = [];
  public isFetching: boolean = false;
  public fetchError: boolean = false;
  public isPuting: boolean = false;
  public putError: boolean = false;
  public isDeleting: boolean = false;
  public deleteError: boolean = false;
}

const mutations = {
  [SMART_TAG_FETCH_REQUEST](state) {
    state.fetchError = false;
    state.isFetching = true;
  },
  [SMART_TAG_FETCH_SUCCESS](state, payload: SmartTag[]) {
    state.fetchError = false;
    state.isFetching = false;
    state.smartTags = payload;
  },
  [SMART_TAG_FETCH_FAILURE](state) {
    state.fetchError = true;
    state.isFetching = false;
  },
  [SMART_TAG_PUT_REQUEST](state) {
    state.isPuting = true;
    state.putError = false;
  },
  [SMART_TAG_PUT_SUCCESS](state, payload: SmartTag) {
    state.isPuting = false;
    state.putError = false;
    const index = state.smartTags.findIndex(smartTag => smartTag.id === payload.id);
    Vue.set(state.smartTags, index !== -1 ? index : state.smartTags.length, payload);
  },
  [SMART_TAG_PUT_FAILURE](state) {
    state.isPuting = false;
    state.putError = true;
  },
  [SMART_TAG_DELETE_REQUEST](state) {
    state.isDeleting = true;
    state.deleteError = false;
  },
  [SMART_TAG_DELETE_SUCCESS](state, payload: SmartTag) {
    state.isDeleting = false;
    state.deleteError = false;
    const index = state.smartTags.findIndex(smartTag => smartTag.id === payload.id);
    state.smartTags.splice(index, 1);
  },
  [SMART_TAG_DELETE_FAILURE](state) {
    state.isDeleting = false;
    state.deleteError = true;
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }, params): any {
    return new Promise((resolve, reject) => {
      commit(SMART_TAG_FETCH_REQUEST);
      axios({
        url: `${API_URL}/smarttags`,
        params
      }).then(
        response => {
          const payload: SmartTag[] = response && response.data && response.data.smarttags;
          commit(SMART_TAG_FETCH_SUCCESS, payload);
          resolve(payload);
        },
        error => {
          commit(SMART_TAG_FETCH_FAILURE);
          reject(error);
        }
      );
    });
  },
  put({ commit }, payload: SmartTag): any {
    return new Promise((resolve, reject) => {
      commit(SMART_TAG_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'tags', 'exact_match', 'order']);
      axios({
        url: `${API_URL}/smarttags/${payload.id}`,
        method: 'put',
        data
      }).then(
        response => {
          const smarttag: SmartTag = response && response.data && response.data.smarttag;
          commit(SMART_TAG_PUT_SUCCESS, smarttag);
          resolve(smarttag);
        },
        error => {
          commit(SMART_TAG_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  post({ commit }, payload: SmartTag): any {
    return new Promise((resolve, reject) => {
      commit(SMART_TAG_PUT_REQUEST);
      const data = _.pick(payload, ['name', 'tags', 'exact_match', 'order']);
      axios({
        url: `${API_URL}/smarttags`,
        method: 'post',
        data
      }).then(
        response => {
          const smarttag: SmartTag = response && response.data && response.data.smarttag;
          commit(SMART_TAG_PUT_SUCCESS, smarttag);
          resolve(smarttag);
        },
        error => {
          commit(SMART_TAG_PUT_FAILURE);
          reject(error);
        }
      );
    });
  },
  delete({ commit }, payload: SmartTag): any {
    return new Promise((resolve, reject) => {
      commit(SMART_TAG_DELETE_REQUEST);
      axios({
        url: `${API_URL}/smarttags/${payload.id}`,
        method: 'delete'
      }).then(
        response => {
          commit(SMART_TAG_DELETE_SUCCESS, payload);
          resolve(response);
        },
        error => {
          commit(SMART_TAG_DELETE_FAILURE);
          reject(error);
        }
      );
    });
  }
} as ActionTree<State, any>;

const getters = {
  smartTagWithSlug(state): (slug: string) => SmartTag | null {
    return (slug: string): SmartTag | null => {
      const results: SmartTag[] = state.smartTags.filter(smartTag => smartTag.slug === slug);
      return results.length === 1 ? results[0] : null;
    };
  },
  smartTagById(state): (id: number) => SmartTag | null {
    return (id: number): SmartTag | null => {
      const results: SmartTag[] = state.smartTags.filter(smartTag => smartTag.id === id);
      return results.length === 1 ? results[0] : null;
    };
  }
} as GetterTree<State, SmartTag>;

export const smartTags = {
  namespaced: true,
  state: new State(),
  mutations,
  actions,
  getters
};










































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import { Category } from '@/types';

@Component({
  components: {},
  name: 'DropdownList',
})
export default class DropdownList extends Vue {
  @Prop()
  private categories!: Category[];
  @Prop({ default: 0 })
  private currentLevel!: number;
  @Prop({ default: 0 })
  private maxNestedListLevels!: number;
  @State('isPuting', { namespace: 'categories' })
  public isPuting!: boolean;
  @State('isOpen', { namespace: 'menu' })
  public isOpen!: boolean;
  @State('isFrozen', { namespace: 'menu' })
  public isFrozen!: boolean;
  @Action('open', { namespace: 'menu' })
  public openMenu!: any;
  @Getter('hasChildren', { namespace: 'categories' })
  public hasChildren: any;
  @Getter('children', { namespace: 'categories' })
  public children: any;
  private isHover: boolean = false;

  @Watch('isOpen')
  public isMenuOpenChanged() {
    if (!this.isOpen) {
      this.closeAll();
    }
  }

  protected shouldShowArrow(category: Category) {
    return this.currentLevel >= this.maxNestedListLevels && this.hasChildren(category);
  }

  get nextLevel() {
    return this.currentLevel + 1;
  }

  /**
   * Return true if the children should be shown as an hovering submenu,
   * and not being displayed directly in the list.
   */
  protected shouldEnableSubLevel(category: Category) {
    return this.currentLevel >= this.maxNestedListLevels && this.hasChildren(category);
  }

  /**
   * On mouseEnter, show the sublevel if needed.
   * Transitions inside the element, to/from descendants, are not counted, unlike mouseOver/mouseOut.
   * Meaning that even when moving the mouse inside the child submenu, the mouseLeave will not be fired.
   */
  protected mouseEnter(event: any, category: Category) {
    event.stopPropagation();
    event.currentTarget.parentElement.classList.add('is-enter');
    if (this.shouldEnableSubLevel(category)) {
      this.openSubmenu(event, category);
    }
  }

  protected mouseLeave(event: any, category: Category) {
    if (this.isFrozen) {
      return;
    }
    event.stopPropagation();
    event.currentTarget.parentElement.classList.remove('is-enter');
    if (this.shouldEnableSubLevel(category)) {
      this.closeSubmenu(category);
    }
  }

  /**
   * On mouseOver, show the three dots menu for the hover element.
   * When moving inside a child, the mouseOut will be fired.
   */
  protected mouseOver(event: any) {
    event.stopPropagation();
    event.currentTarget.parentElement.classList.add('is-hover');
  }

  protected mouseOut(event: any) {
    event.stopPropagation();
    event.currentTarget.parentElement.classList.remove('is-hover');
  }

  /**
   * Open and position the submenu based on the hovered element and the height of the submenu.
   */
  protected openSubmenu(event: any, category: Category) {
    if (!this.isOpen) {
      // Set the menu state to open
      this.openMenu();
    }
    let topPosition = 0;
    let headerHeight = 0;
    // Get the hovered element boundingRect before displaying the child
    // to get the correct height
    const boundingRect = event.currentTarget.getBoundingClientRect();

    // Show the child (display block) so we can get its height
    const childEl: HTMLElement = (<HTMLElement[]>this.$refs[`sublevel-${category.slug}`])[0];
    childEl.style.display = 'block';

    // Compute the child top position, based on the current element boundingRect and the child height
    topPosition = this.calculateTop(boundingRect, childEl.offsetHeight);
    topPosition = Math.max(0, Math.floor(topPosition) - headerHeight);
    let left = this.calculateLeft(event.currentTarget.getBoundingClientRect());
    childEl.style.left = `${left}px`;
    childEl.style.top = `${topPosition}px`;
  }

  protected calculateTop(boundingRect: any, outerHeight: any) {
    const windowHeight = window.innerHeight;
    const bottomOverflow = windowHeight - (boundingRect.top + outerHeight);

    return bottomOverflow < 0
      ? boundingRect.top - outerHeight + boundingRect.height
      : boundingRect.top;
  }

  protected calculateLeft(boundingRect: any) {
    return boundingRect.right - 20;
  }

  protected closeSubmenu(category: Category) {
    const childEl: HTMLElement = (<HTMLElement[]>this.$refs[`sublevel-${category.slug}`])[0];
    childEl.style.display = 'none';
  }

  protected closeAll() {
    const sublevels = document.getElementsByClassName('sublevel-items');
    for (let sublevel of sublevels) {
      (<HTMLElement>sublevel).style.display = 'none';
    }
  }

  protected scrollToTop() {
    window.scrollTo(0, 0);
  }
}

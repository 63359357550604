






























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({})
export default class App extends Vue {
  @State('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;
  private isMobileMenuVisible: boolean = false;
  API_URL = process.env.VUE_APP_API_LOCATION;

  @Watch('$route', { immediate: true, deep: true })
  // @ts-ignore
  public onUrlChange(newVal: any) {
    this.isMobileMenuVisible = false;
  }

  get appUrl() {
    return window.location.protocol + '//' + window.location.host;
  }

  get logoutUrl() {
    var host = window.location.host;
    var protocol = window.location.protocol;
    var redirectTo = encodeURIComponent(protocol + '//' + host);
    return process.env.VUE_APP_AUTH_LOGOUT_PATH + '?rd=' + redirectTo;
  }

  protected toggleMobileMenu() {
    this.isMobileMenuVisible = !this.isMobileMenuVisible;
  }

  protected launch_export() {
    window.open(this.API_URL + '/export', '_blank');
  }
}

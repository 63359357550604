
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SmartTag, FieldsErrors } from '@/types';
import { APIError, ValidationError } from '@/errors';

export const EVENT = {
  EDIT_SAVE_SUCCESS: 'EDIT_SAVE_SUCCESS',
  EDIT_SAVE_FAILURE: 'EDIT_SAVE_FAILURE',
  EDIT_DELETE_SUCCESS: 'EDIT_DELETE_SUCCESS',
  EDIT_DELETE_FAILURE: 'EDIT_DELETE_FAILURE',
  EDIT_CANCELED: 'EDIT_CANCELED'
};

@Component({})
export default class EditMixin extends Vue {
  @Prop()
  public smartTag!: SmartTag;
  @Action('delete', { namespace: 'smartTags' })
  public delete: any;
  @Action('put', { namespace: 'smartTags' })
  public put: any;
  @Action('post', { namespace: 'smartTags' })
  public post: any;
  protected saveError: boolean = false;
  protected saveFieldsErrors: FieldsErrors = {};
  private originalSmartTag?: SmartTag;

  // @ts-ignore
  protected editEvent(event: string, payload: any) {
    // Can be overriden
  }

  protected editionStarted() {
    this.originalSmartTag = Object.assign({}, this.smartTag);
  }

  protected remove() {
    if (this.smartTag !== undefined) {
      this.delete(this.smartTag)
        .then(() => {
          this.editEvent(EVENT.EDIT_DELETE_SUCCESS, this.smartTag);
        })
        .catch((error: APIError) => {
          this.editEvent(EVENT.EDIT_DELETE_FAILURE, error);
        });
    }
  }

  protected async save() {
    this.saveError = false;
    this.saveFieldsErrors = {};
    try {
      if (this.smartTag.id !== undefined) {
        await this.put(this.smartTag);
      } else {
        await this.post(this.smartTag);
      }
      this.editEvent(EVENT.EDIT_SAVE_SUCCESS, this.smartTag);
    } catch (error) {
      this.saveError = true;
      if (error instanceof ValidationError && error.validation !== undefined) {
        this.saveFieldsErrors = error.validation;
      }
      this.editEvent(EVENT.EDIT_SAVE_FAILURE, error);
    }
  }

  protected cancel() {
    Object.assign(this.smartTag, this.originalSmartTag);
    this.saveError = false;
    this.saveFieldsErrors = {};
    this.editEvent(EVENT.EDIT_CANCELED, null);
  }
}















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import draggable from 'vuedraggable';
import { Dashboard } from '@/types';
import ListItem from './ListItem.vue';

import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    draggable,
    ListItem,
  },
})
export default class DashboardsList extends Vue {
  @Prop()
  public dashboards!: Dashboard[];
  @Prop({ default: true })
  public draggable!: boolean;
}

import Vue from 'vue';
import Vuex from 'vuex';
import { MutationTree, ActionTree } from 'vuex';
import { Tag } from '@/types';
import axios from 'axios';

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_LOCATION;

export class State {
  public tags: Tag[] = [];
  public error: boolean = false;
}

const mutations = {
  tagsLoaded(state, payload: Tag[]) {
    state.error = false;
    state.tags = payload;
  },
  tagsError(state) {
    state.error = true;
    state.tags = [];
  }
} as MutationTree<State>;

const actions = {
  fetch({ commit }): any {
    return new Promise((resolve, reject) => {
      axios({
        url: `${API_URL}/bookmarks`
      }).then(
        response => {
          const payload: Tag[] = response && response.data && response.data.tags;
          commit('tagsLoaded', payload);
          resolve(payload);
        },
        error => {
          commit('tagsError');
          reject(error);
        }
      );
    });
  }
} as ActionTree<State, any>;

export const tags = {
  namespaced: true,
  state: new State(),
  mutations,
  actions
};
